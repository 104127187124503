import ls from 'local-storage';
import {toast} from "react-toastify";
import agent from '../../utils/CommunicationAgent';
import TranslationMgr from '../../utils/TranslationManager';
import {SERVICES_ENUM, subdomain, subdomainEnv} from '../../constants';
import {RoleService} from "../../utils/RoleService";

const LOADED_STATE = ls.get('axuris.core');


const INITIAL_STATE = {
    authenticated: false,
    lastInteraction: 0,
    sessionTime: 60 * 60 * 1000, // TODO REPLACE BY A CONFIG
    services: [],
    allServices: [],
    extraAlarmTypes: [],
    jwtToken: null,
    permissions: null,
    roles: null,
    templates: {},
    actions: {},
    currentDictionary: null,
    dictionaries: [],
    languages: [],
    currentLanguage: null,
    isMobileMode: false,
    alarms: [],
    displayOnlyActive: true,
    isHapaSubdomain: false,
    error: null,
    isBobstSubdomain: false,
    isDemoSubdomain: false,
    userRoles: {},
    isDev: false,
};

if (subdomainEnv === 'dev' || subdomain === 'localhost:3000') {
    INITIAL_STATE.isDev = true;
    if (LOADED_STATE !== null) {
        LOADED_STATE.isDev = true;
    }
}

if (subdomain === 'hapa' || subdomain === 'hapa1') {
    INITIAL_STATE.isHapaSubdomain = true;
    if (LOADED_STATE !== null) {
        LOADED_STATE.isHapaSubdomain = true;
    }
}

if (subdomain === 'demo' || subdomain === 'demo') {
    INITIAL_STATE.isDemoSubdomain = true;
    if (LOADED_STATE !== null) {
        LOADED_STATE.isDemoSubdomain = true;
    }
}

if (subdomain === 'bobst') {
    INITIAL_STATE.isBobstSubdomain = true;
    if (LOADED_STATE !== null) {
        LOADED_STATE.isBobstSubdomain = true;
    }
}

const model = {
    name: 'core',
    state: LOADED_STATE || INITIAL_STATE,
    reducers: {

        authenticate(state, jwtToken) {
            document.cookie = `jwt-cookie=${jwtToken.slice(7)}${window.location.protocol !== 'http:' ? '; Secure;' : ''}`;
            return { ...state, authenticated: true, lastInteraction: Date.now(), jwtToken };
        },

        logout(state) {
            ls.clear();
            document.cookie = "jwt-cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            return { ...state, ...INITIAL_STATE };
        },

        reset(state) {
            return { ...state, ...INITIAL_STATE };
        },

        loadAvailableServices(state, services) {
            return  { ...state, allServices: services };
        },

        changeMobileMode(state, payload) {
            return { ...state, isMobileMode: payload };
        },

        setUserRoles(state, payload) {
            return { ...state, userRoles: payload };
        },

        openService(state, service) {
            const services = state.services.map((s) => {
                s.selected = false;
                return s;
            });

            for (let i = 0; i < services.length; i++) {
                if (services[i].serviceName === service.serviceName) {
                    services[i].selected = true;
                    return { ...state, services };
                }
            }
            service.selected = true;
            services.push(service);
            return { ...state, services };
        },

        updateCoreTranslations(state){
            return { ...state, updateTranslations: true};
        },

        switchToService(state, serviceName) {
            const services = state.services.map((service) => {
                service.selected = service.serviceName === serviceName;
                return service;
            });

            return { ...state, services };
        },

        communicateWithService(state, {serviceName, item}){
            const services = [...state.services];
            let serviceFound = false;
            services.forEach((s) => {
                s.selected = false;
                if(s.serviceName === serviceName){
                    serviceFound = true;
                    s.selected = true;
                }
            });

            !serviceFound && state.allServices.forEach(service => {
                if(service.serviceName === serviceName){
                    service.selected = true;
                    services.push(service);
                }
            });

            return { ...state, services, serviceNavigationParams: item };
        },

        cleanServiceNavigationParams(state){
            return { ...state, serviceNavigationParams: null};
        },

        closeService(state, serviceName) {
            let indexToDelete = -1;
            const servicesCopy = [ ...state.services ];

            for (let i = 0; i < state.services.length; i++) {
                if (state.services[i].serviceName === serviceName) {
                    indexToDelete = i;
                    break;
                }
            }

            servicesCopy.splice(indexToDelete, 1);

            return { ...state, services: servicesCopy };
        },

        setupCommunicationAgent(state) {
            agent.setJwtToken(state.jwtToken);
            return { ...state };
        },

        setupTranslations(state, dictionnary) {
            return { ...state, translations: dictionnary, updateTranslations: false };
        },

        fetchTemplates(state, payload) {
            return { ...state, templates: payload };
        },

        fetchActions(state, payload) {
            return { ...state, actions: payload };
        },

        setCurrentDictionary(state, payload) {
            const currentTranslationData = { ...payload };

            Object.keys(currentTranslationData).forEach(cur => {
                if(currentTranslationData[cur].data) {
                    Object.keys(currentTranslationData[cur].data).forEach(d => {
                        state.languages.forEach(lang => {
                            if (currentTranslationData[cur].data[d].lcid === lang.lcid) {
                                currentTranslationData[cur].data[d].checked = lang.checked;
                            }
                        });
                    });
                }
            });

            return { ...state, currentDictionary: currentTranslationData };
        },

        fetchTranslationsData(state, payload) {
            return { ...state,  dictionaries: payload };
        },

        fetchLanguages(state, payload) {
            return { ...state, languages: payload };
        },

        fetchAlarmTypes(state, payload) {
            return { ...state, extraAlarmTypes: payload};
        },

        setError(state, error) {
            return {...state, error};
        },

        updateCurrentDictionary(state, payload) {
            return { ...state, currentDictionary: payload };
        },

        setupCurrentLanguage(state, language) {
            return { ...state, currentLanguage: language };
        },

        fetchAlarms(state, newAlarms) {
            const services = [...state.services];
            const service = state.allServices.find((s) => s.serviceName === SERVICES_ENUM.MY_ALARMS);

            if(JSON.stringify(state.alarms) !== JSON.stringify(newAlarms) && !service.selected) {
                if(services.every(serv => serv.serviceName !== SERVICES_ENUM.MY_ALARMS)) {
                    services.push(service);
                }
            }

            return {...state, alarms: newAlarms, services};
        },

        alarmMute(state, alarm) {
            const alarms = [ ...state.alarms ];

            alarms.forEach(accordionField => {
                if (accordionField.id === alarm.id) {
                    accordionField.muted = !accordionField.muted;
                }
            });

            return { ...state, alarms };
        },

        resolveAlarm(state, {currentAlarmId, reportMessage}, callback) {
            const alarms = JSON.parse(JSON.stringify(state.alarms));

            alarms.forEach(accordionField => {
                if (accordionField.id === currentAlarmId) {
                    accordionField.reportMessage = reportMessage;
                    callback && callback(accordionField);
                }
            });

            return { ...state, alarms };
        },

        deleteAlarm(state, currentAlarmId) {
            const alarms = [ ...state.alarms ];
            const newAlarms = alarms.map(alarm => {
                if(alarm.id === currentAlarmId) {
                    alarm.status = 'INACTIVE';
                }
                return alarm;
            });

            return { ...state, alarms: newAlarms };
        },

        onlyActiveFilterChange(state, payload) {
            return { ...state,  displayOnlyActive: payload };
        },

        fetchPermissions(state, payload) {
            return { ...state, ...{ permissions: payload }};
        },

    },
    effects: (dispatch) => ({

        async loadAvailableServicesAsync(payload, rootstate) {

            if (!rootstate.core.authenticated) {
                return;
            }

            const response = await agent.get('/myservices/api/v1/userservices');
            const {data: services = []} = response;
            const availableService = [];
            const ids = services.map((service) => service.serviceName);
            let dictionnaries;
            try {
                dictionnaries = await TranslationMgr.getDictionnaries(ids, payload);
            } catch {
                dictionnaries = {};
            }

            // TODO move to separate function
            const {permissions, userRoles} = rootstate.core;

            const currentUserRoleNames = Object.values(RoleService.instance.getCurrentUserData().userRole)
                .map(el => el.replace('ROLE_', ''));
            const currentUserRoles = Object.values(userRoles).filter(el => {

                return currentUserRoleNames.includes(el.name.toUpperCase());
            });


            const currentUserRoleIds = currentUserRoles.map(role => role.id);

            const availablePermissions = permissions.filter(permission => currentUserRoleIds.includes(permission.roleId)).filter(el => el.access === 1);

            if (currentUserRoleNames.includes('ADMIN')) {
                availablePermissions.push({
                    "serviceId": "myPermissions",
                });
            }

            const uniquePermissions = availablePermissions.filter((obj, index, self) => index === self.findIndex((el) => el.serviceId === obj.serviceId));

            for (let i = 0; i < services.length; i++) {
                uniquePermissions.map(el => {
                   if (el.serviceId === services[i].serviceName) {
                       const service = services[i];
                       const id = service.serviceName;
                       const dic = dictionnaries[id] ? dictionnaries[id] : {};
                       service.translatedLabel = dic.get ? dic.get("service_label") : id;
                       availableService.push(service);
                    }
                });

            }

            dispatch.core.loadAvailableServices(availableService);
        },


        async loadRolesAsync() {

            try {
                let response;
                await agent.get('/mypermissions/api/v1/roles').then(res =>{
                    response = res.data;
                });
                dispatch.core.setUserRoles(response);
            } catch(e) {
                console.error('Error Load User Roles: ', e);
            }

        },

        async fetchPermissionsAsync(){
            let response;
            await agent.get('/mypermissions/api/v1/permissions').then(res =>{
                response = res.data;
            });

            dispatch.core.fetchPermissions(response);
        },

        async checkLastInteractionAsync(payload, rootState) {

            if (rootState.core.lastInteraction > 0 && Date.now() - rootState.core.lastInteraction > rootState.core.sessionTime) {
                dispatch.core.reset();

                if (dispatch.baaslogin) {
                    dispatch.baaslogin.displayLogoutScreen();
                }
            }

        },

        async setupTranslationsAsync(lang) {
            const dictionnary = await TranslationMgr.getDictionnary('core', lang);
            dispatch.core.setupTranslations(dictionnary);
        },

        async catchErrorAsync(errorWithInfo, state) {
            const errorWithState = {
                state,
                ...errorWithInfo
            };
            await agent.post('/errorhandler/api/v1/error', errorWithState);
        },

        async addStaticResourceAsync(payload) {
            agent.post(`/myresources/api/v1/resource/${payload.resource.id}`,payload.resource.formData).then(() => {
                payload.resource.callback();
            }).catch(e => {
                console.error(e);
            });
        },

        async fetchTemplatesAsync() {
            agent.get('/mytemplates/api/v1/templates').then(res => {
                dispatch.core.fetchTemplates(res.data);
            }).catch(e => {
                console.error(e);
            });
        },

        async fetchTranslationsDataAsync() {
            let response;
            try {
                response = await agent.get('/mytranslations/api/v1/dictionaries');
            } catch {
                response = {};
            }
            if (response.data) {
                dispatch.core.fetchTranslationsData(response.data);
            }
        },

        async fetchAlarmsAsync(alarmsQuery) {
            const encodedQuery = encodeURIComponent(JSON.stringify(alarmsQuery));
            agent.get(`/myalarms/api/v1/alarms/${encodedQuery}`)
                .then(res => {
                    dispatch.core.fetchAlarms(res.data);
                })
                .catch(e => {
                    console.error(e);
                });
        },

        async updateAlarmAsync(alarm) {
            return agent.put(`/myalarms/api/v1/alarms/${alarm.id}`, alarm)
                .then(res => {
                    return res.status;
                })
                .catch(e => {
                    console.error(e);
                });
        },


        async fetchAlarmTypesAsync(){
            const ids = ['27cf4ff0-ac37-11ed-8aab-9735e9136ef4', 'e434db20-b12c-11ed-a1eb-6d6cfcaa00de', '78ea8890-c272-11ed-91ff-9ba61c66a7a8', '8ab6f5e0-c272-11ed-91ff-9ba61c66a7a8'];
            try {
                const response = await agent.get(`/myalarms/api/v1/alarmtypes`);
                const extraAlarmTypes = response.data.filter(el => ids.includes(el.itemId));
                dispatch.core.fetchAlarmTypes(extraAlarmTypes);
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async callTestAlarmAsync(props) {
            const {id, deviceId, successText, errorText} = props;
            try {
                const response = await agent.get(`/myalarms/api/v1/alarmtypes/${id}/details`);
                const item = response.data;

                const data = {
                    alarmSummary: item.labelKey.value,
                    alarmType: item.name.value,
                    latitude: 46.569718,
                    longitude: 6.553365,
                    deviceId,
                };

                await agent.post(`/myalarms/api/v1/alarms/test`, data);
                toast(successText, {
                    theme: "light",
                    hideProgressBar: true,
                });

            } catch (e) {
                console.log('Error: ', e);
                toast.error(errorText, {
                    theme: "light",
                    hideProgressBar: true,
                });
            }
        },
    })
};

export default model;
