import React, {lazy, Suspense} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Spinner from '../components/Spinner';
import TranslationUtil from '../../../utils/TranslationUtil';
import styles from './styles';
import MainScreen from './MainScreen';

const mapState = state => ({
    state: state.core,
});

const mapDispatch = dispatch => ({
    setupCommunicationAgent: () => dispatch.core.setupCommunicationAgent(),
    checkLastInteractionAsync: () => dispatch.core.checkLastInteractionAsync(),
    setupTranslationsAsync: (lang) => dispatch.core.setupTranslationsAsync(lang),
    setupTranslations: (translations) => dispatch.core.setupTranslations(translations),
    setCurrentDictionary: (dictionary) => dispatch.core.setCurrentDictionary(dictionary),
    fetchTranslationsDataAsync: () => dispatch.core.fetchTranslationsDataAsync(),
    fetchLanguagesAsync: () => dispatch.core.fetchLanguagesAsync(),
});

/**
 * The core screen is the one :
 * - Displaying the tabbed screen with the home screen
 * - Loading (Lazy) the login screen
 */
class CoreScreen extends React.Component {

    async componentDidMount() { 
        this.props.setupCommunicationAgent();
        // check every second if the session has not expired. See also src/utils/middleware/SaveStateMiddleware.js
    }

    componentDidUpdate(){
        this.props.state.updateTranslations && this.setupTranslations();
    }

    /**
     * Setup tranlation for current tab.
     */
    setupTranslations = () => {
        const { dictionaries, currentLanguage } = this.props.state;
        const { setupTranslations } = this.props;
        const translations = TranslationUtil.getCurrentTranslations(dictionaries, currentLanguage, 'Core');
        setupTranslations(translations);
    }  

    render() {  
        // the class property is injected throw the "withStyles" function (see bottom of the page)
        const { classes } = this.props;
        const state = this.props.state ? this.props.state : {};

        if(state.authenticated) {
            return (
                <MainScreen />
            );
        }
        
        // TODO this might be replaced by a config loading another 
        const Login = lazy(() => import('../../baaslogin/screens/LoginScreen'));
        const Onboard = lazy(() => import('../../baaslogin/components/Onboard'));

        return (
            <Router>
                <div className={classes.root}>
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                            <Route path="/onboard">
                                <Onboard />
                            </Route>
                            <Route path="/">
                                <Login />
                            </Route>
                        </Switch>
                    </Suspense>
                </div>
            </Router>
        );
        
    }
}

CoreScreen.propTypes = {
    state: PropTypes.object.isRequired,
    setupCommunicationAgent: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    setupTranslations: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(withStyles(styles)(CoreScreen));
