class SearchResult {

    constructor(index){
        this.index = index;
        this.fields = {};
    }

    getIndex() {
        return this.index;
    }

    getFields() {
        return this.fields;
    }

    addField(fieldKey, fieldValue) {
        this.fields[fieldKey] = fieldValue;
    }
}

export default SearchResult;