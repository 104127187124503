const styles = theme => ({
    root: {
        flexGrow: 1,
        
    },
    tabContainer: {
        padding: 0,
        textAlign: 'left',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        marginBottom:theme.spacing.unit * 2,
        backgroundColor:'rgba(4, 110,172, 1)',
    },
    tabPane: {
        zIndex: 0,
        color: theme.palette.text.secondary,
        padding: theme.spacing.unit * 2,
        marginTop: -theme.spacing.unit,
        // background: 'linear-gradient(#FFF,#EEEEFF)',
        // height: window.innerHeight - (window.innerHeight / 4)
        position: 'absolute',
        left:theme.spacing.unit * 2,
        right:theme.spacing.unit * 2,
        bottom:theme.spacing.unit * 2,
        top:100,
        overflow: 'hidden',
        backgroundColor:'rgba(245,249,252, 1)',
        borderRadius: '25px',
        display: 'flex',
        flexDirection: 'column',
        '@media(max-width: 800px)': {
            top: '80px',
            right: 0,
            left: 0,
            bottom: 0,
            borderRadius: 0,
            overflow: 'auto',
            padding: 0,
        }
    },
    tabBarInnerScroll : {
        display: 'flex',
        marginTop:'-10px',
        paddingRight:'35px'
    },
    sideContent : {
        overflow:'hidden',
        height: theme.spacing.unit * 7,
        
        paddingRight: theme.spacing.unit
    },
    fullScreen: {
        position: 'fixed',
        zIndex: 10,
        bottom: '30px',
        right: '30px',
        outline: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer'
    },
    topShadow: {
        position:'absolute',
        left:0,
        top: '-3px',
        right:0,
        height:'1px',
        background:'none',
        zIndex:2,
        boxShadow:'0 3px 2px 0 rgba(0, 0, 0, 0.2)'

    },
    logo: {
        position:'absolute',
        width:'200px',
        height:'60px',
        backgroundImage:'url(\'ax_logo_no_subtitle.svg\')',
        right:theme.spacing.unit * 2,
        top:theme.spacing.unit * 2,
        zIndex:1000,
        backgroundSize:'80%',
        backgroundPosition:'center 5px',
        backgroundRepeat:'no-repeat',
        backgroundColor: '#FFF',
        borderRadius: '40px',
        boxShadow:'2px 2px 2px 0 rgba(0, 0, 0, 0.2)',
        '@media(max-width: 800px)': {
            right: '50%',
            transform: 'translate(50%)',
            width: '135px',
            backgroundPosition: 'center 10px',
            height: '50px',
            top: '10px',
        }
    },
    logout: {
        position: 'absolute',
        right: '15px',
        top: '15px',
        outline: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer',
        zIndex: 10,
        backgroundSize: '60%',
        '@media(max-width: 800px)': {
            left: '15px',
            top: '15px',
        }
    },

    tabContentWrapper: {
        height: '100%',
    }
  
});

const icons = {
    exitFullScreen: "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTUgMTZoM3YzaDJ2LTVINXYyem0zLThINXYyaDVWNUg4djN6bTYgMTFoMnYtM2gzdi0yaC01djV6bTItMTFWNWgtMnY1aDVWOGgtM3oiIHN0eWxlPSIgICAgZmlsbDogI2ZmZjsiLz48L3N2Zz4=)",
    fullScreen: "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTcgMTRINXY1aDV2LTJIN3YtM3ptLTItNGgyVjdoM1Y1SDV2NXptMTIgN2gtM3YyaDV2LTVoLTJ2M3pNMTQgNXYyaDN2M2gyVjVoLTV6IiBzdHlsZT0iICAgIGZpbGw6ICNmZmY7Ii8+PC9zdmc+)",
    logout: "url(data:image/png+xml;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAABYElEQVR4Ae3UJZRVURiA0R9314K7Q4OCJqTilqfiLn3hTsO94ESchFubNAl3Z9O57849uO6+vuMnvt1/hlpmmaHxrSwDy35lSOOikMZRzBIVulQW0kWFeUWZWeC+tnkh7d0HsyKfbt6DI6rmhqrYCN7rHnmcB5fULNij7eBcXmYUeK9b4WY39wiMjlLsAutTjt98sDuy1PYKdE0KtQUv1cmGBoFrqRfSFTAwGxoH9ieH9oNx2dBMsCo5tArMzAutTA6tzAtNAvtTvxH7waRsaAgoj0TKQfafUtNL0DcS6AteqhlZDoGtkcBWcChKMRp80CsK6OkjGBOluQBuqR2VUNttcCny6O8t2KFa5FDNdvDWgMhnOuCM1lGC1s4ApqR99jw3V+vPInM9ByyPYsp8BHDXIauscshdAB+VRRpD3ZDnhqGRThXTnfQWALxxynRV4supZ4Qp5pltqmHqxX/f5BPuuxFv0qRsHAAAAABJRU5ErkJggg==)"
};

export default styles;
export {icons};