import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import TranslationUtil from "../../../../utils/TranslationUtil";


const ExtraCall = (props) => {
    const {
        classes,
        items,
        callTestAlarmAsync,
        dictionaries,
        currentLanguage,
    } = props;

    const translations = TranslationUtil.getCurrentTranslations(dictionaries, currentLanguage, 'Core');
    const testTranslations = TranslationUtil.getCurrentTranslations(dictionaries, currentLanguage, 'My Alarm Types');

    const callAlarm = async (id) => {
        const successText = TranslationUtil.getTranslation(translations, "test_alarm_is_triggered");
        const errorText = TranslationUtil.getTranslation(translations, "error_to_call_test_alarm");
        const systemAlarm = TranslationUtil.getTranslation(testTranslations, "ax_system_alarm");
        await callTestAlarmAsync({id, deviceId: systemAlarm, successText, errorText});
    };

    const buttons = (items || []).sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });
    return (
        <div className={classes.wrapper}>
            {(buttons && buttons.length > 0) && buttons.map(el => (
                <div onClick={() => callAlarm(el.itemId)} className={classes.item}>
                    <span className={classes.imageWrapper}>
                        <img className={classes.image} src='../axstandard/icons/ax-alarm-types-small.svg' alt='group' />
                    </span>
                    <span className={classes.text}>
                        {el.name}
                    </span>
                </div>
            ))}

        </div>
    );
};


ExtraCall.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    dictionaries: PropTypes.object,
    currentLanguage: PropTypes.object,
    callTestAlarmAsync: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExtraCall);
