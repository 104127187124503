import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './styles';


function TooltipButton(props) {
    const { classes, icon, title, size = 'small', isFloating = true, onClick, customClass, id, smallButtons, children} = props;
    const buttonStyle = { backgroundImage: icon, width: 24, height: 24, marginBottom: '3px', marginTop: '2px', marginLeft: 0, marginRight: 0 };
    let fabStyle = { backgroundImage: icon};
    fabStyle = smallButtons ? {...fabStyle, ...{minHeight:'25px', width:'25px', height:'25px', backgroundSize:'70%'}} : fabStyle;

    return (

        <Tooltip title={title}>
            {isFloating ? (
                <Fab color="primary" className={customClass || classes.fab} size={size} onClick={onClick} style={fabStyle}>
                    <>
                        <div />
                        {children}
                    </>
                </Fab>
            )
                :
                <div id={id} role="button" tabIndex={0} className={customClass || classes.fab} onClick={onClick} onKeyPress={onClick} style={buttonStyle}>{children}</div>}
        </Tooltip>

    );
}

TooltipButton.propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.node,
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    onClick: PropTypes.func,
    isFloating: PropTypes.bool,
    customClass: PropTypes.any,
    smallButtons: PropTypes.bool,
    id: PropTypes.string,
    children: PropTypes.any,
};

export default withStyles(styles)(TooltipButton);