import agent from './CommunicationAgent';

const GET_TRANSLATIONS_URL = '/mytranslations/api/v1/translations/';

const cache = {};

/**
 * Helps to get dictionnary (function) in a given language that 
 * will be next used to get translations by key.
 * The data uses the ARB format:
 * https://github.com/googlei18n/app-resource-bundle/wiki/ApplicationResourceBundleSpecification
 */
class TranslationManager {

    /**
     * 
     * @param {String} dictionnaryId is the ID of the dictionnary we want to access.
     *                               Usually the dictionnary matches the service name or "client_subdomain_sevice_name"
     * @param {String} lang is the language within which we want the dictionnary. It's usually the 2 digits code ("en", "fr", "de",...)
     */
    async getDictionnary(dictionnaryId, lang) {

        if (cache[dictionnaryId] && cache[dictionnaryId][lang]) {
            return cache[dictionnaryId][lang];
        }

        const url = GET_TRANSLATIONS_URL + dictionnaryId + '/' + lang + '/';
        const response = await agent.get(url);
        
        const {data} = response && response.data ? response : {data:{}};
        const keys = Object.keys(data); 
        const aData = data[keys[0]];
        
        const dictionnary = new TranlsationDictionnary(aData);

        cache[dictionnaryId] = cache[dictionnaryId] ? cache[dictionnaryId] : {};
        cache[dictionnaryId][lang] = dictionnary;

        return dictionnary;

    }

    async getDictionnaries(dictionnaryIds, lang) {

        let ids = '';

        const result = {};

        for(let i = 0; i < dictionnaryIds.length; i++) {

            const dictionnaryId = dictionnaryIds[i];
            if (cache[dictionnaryId] && cache[dictionnaryId][lang]) {
                result[dictionnaryId] = cache[dictionnaryId][lang];
                continue;
            }

            ids += dictionnaryId + ',';

        }

        if(ids.length > 0) {
            const url = GET_TRANSLATIONS_URL + ids + '/' + lang + '/';
            
            let response;
            try {
                response = await agent.get(url);
            } catch(e) {
                console.log('Failed to load translations:' + e);
            }
            
            const {data} = response && response.data ? response : {data:{}};

       
            const keys = Object.keys(data);
            
            for(let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const dictionnary = new TranlsationDictionnary(data[key]);
                result[key] = dictionnary;
            }

        }

        console.log(cache);

        return result;

    }

}


/**
 * A dictionnary helps to handle translations in a given language.
 */
class TranlsationDictionnary {

    /**
     * constructor-
     * @param {Map} data is a key/value pair map where the key is a string and the value as well.
     */
    constructor(data) {
        this.data = data;
    }

    /**
     * @param {String} key 
     * @return {String} translation for the give key.
     */
    get(key) {

        if (!key) {
            return null;
        }

        if (!this.data) {
            return '??' + key;
        }
        
        const value = this.data[key];

        if (!value) {
            return '?' + key;
        }

        return value;

    }

    keys() {
        return this.data ? Object.keys(this.data) : [];
    }

    getData() {
        return this.data ? this.data : {};
    }

}


// Singleton pattern : only one agent at a time.
const instance = new TranslationManager();

export default instance;