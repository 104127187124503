import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    spinnerWrapper: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    }
});

class Spinner extends React.Component {

    render() {

        const {classes, customClasses} = this.props;

        return (
            <div className={customClasses || classes.spinnerWrapper}>
                <div className="sk-fading-circle">
                    <div className="sk-circle1 sk-circle" />
                    <div className="sk-circle2 sk-circle" />
                    <div className="sk-circle3 sk-circle" />
                    <div className="sk-circle4 sk-circle" />
                    <div className="sk-circle5 sk-circle" />
                    <div className="sk-circle6 sk-circle" />
                    <div className="sk-circle7 sk-circle" />
                    <div className="sk-circle8 sk-circle" />
                    <div className="sk-circle9 sk-circle" />
                    <div className="sk-circle10 sk-circle" />
                    <div className="sk-circle11 sk-circle" />
                    <div className="sk-circle12 sk-circle" />
                </div>
            </div>
        );
    }
}

Spinner.propTypes = {
    classes: PropTypes.object.isRequired,
    customClasses: PropTypes.string,
};

export default withStyles(styles)(Spinner);