
const styles = theme => ({
    root: {
        flexGrow: 1,
        // maxWidth:theme.spacing.unit * 10
        boxShadow: 'none'
    },
    tabClosable: {
        paddingRight: '28px',
        position: 'relative'
    },
    tab: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        fullWidth: false,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: theme.spacing.unit * 2,
        position: 'relative',
        width: 'auto',
        cursor: 'pointer',
        // background: 'linear-gradient(#FFF,#EEEEFF)'
        backgroundColor:'rgba(230,234,242, 1)',
        boxShadow: '-2px 0px 2px 0px rgba(0,0,0,0.2)',
        borderRadius: '15px',
        borderTopRightRadius: '8px',
        borderRight: '0 none',
        paddingBottom: theme.spacing.unit,
        transition: 'background-color 300ms',
        '@media(max-width: 800px)': {
            margin: 0,
            textAlign: 'left',
            borderRadius: 0,
            backgroundColor:'rgba(245,249,252, 1)',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
            alignItems: 'center',
            height: '64px',
            boxSizing: 'border-box',
            borderBottom: '1px solid #eee',
        }
        
    },
    tabSelected: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        zIndex: 10,
        cursor: 'auto',
        boxShadow: '-2px -3px 2px 0px rgba(0,0,0,0.2)',
        backgroundColor:'rgba(245,249,252, 1)',
        paddingTop: '20px',
        paddingBottom:'15px',
        '@media(max-width: 800px)': {
            margin: 0,
            textAlign: 'left',
            borderRadius: 0,
            boxShadow: 'none',
            backgroundColor: '#fff',
        }
    },
    closeButton: {
        margin: 0,
        padding: 0,
        marginTop: '2px',
        marginLeft: theme.spacing.unit * 5,
        position: 'absolute',
        right: '-14px',
        '@media(max-width: 800px)': {
            position: 'static',
            margin: 0,
        }
    },
    closeButtonIcon: {
        width: theme.spacing.unit * 2,
        height: theme.spacing.unit * 2,
        '@media(max-width: 800px)': {
            width: theme.spacing.unit * 4,
            height: theme.spacing.unit * 4,
        }
    },
    tabIconWrapper: {
        display: 'inline',
        position: 'relative',
        paddingRight: theme.spacing.unit * 4,
        '@media(max-width: 800px)': {
            paddingLeft: '8px',
        }
    },
    tabIcon: {
        position: 'absolute',
        top: -theme.spacing.unit,
        left: 0,
        width: '50px',
        height: '35px',
        backgroundPosition: 'left center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    },
    tabSkewedBorder: {
        position:'absolute',
        display: 'block',
        height:'44px',
        top:0,
        width:'50px',
        right:'-30px',
        transform:'skewX(40deg)',
        zIndex:'-100',
        borderTopRightRadius:'15px',
        backgroundColor:'rgba(230,234,242, 1)',
        // boxShadow: '1px 0px 1px 0 rgba(0, 0, 0, 0.05)',
        transition: 'background-color 300ms',
        '@media(max-width: 800px)': {
            display: 'none',
            boxShadow: 'none',
        }
    },
    tabSkewedBorderSelected: {
        position:'absolute',
        display: 'block',
        height:'54px',
        top:0,
        width:'50px',
        right:'-30px',
        transform:'skewX(40deg)',
        zIndex:'-100',
        borderTopRightRadius:'15px',
        backgroundColor:'rgba(245,249,252, 1)',
        boxShadow: '2px -2px 2px 0 rgba(0, 0, 0, 0.1)',
        '@media(max-width: 800px)': {
            display: 'none',
        }
    },

});

export default styles;
