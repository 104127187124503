import IndexParser from './IndexParser';
import SearchUtil from './SearchUtil';
import TranslationUtil from '../TranslationUtil';
import { SERVICES_ENUM } from "../../constants";

const searchUtil = new SearchUtil();

function getOptions(searchResults, term, translations) {
    const options = [];
        
    searchResults && searchResults.forEach(searchResult => {
        const result = IndexParser.fromSearchResult(searchResult);
        let nodeName;
        let serviceName;
        if (result.fields.itemType === 'user') {
            nodeName = `${result.fields.firstName} ${result.fields.lastName}`;
            serviceName = SERVICES_ENUM.MY_PEOPLE;
        } else if (result.fields.itemType === 'asset') {
            serviceName = SERVICES_ENUM.MY_ASSETS;
            nodeName = `${result.fields.labelKey}`;
        } else if (result.fields.itemType === 'group') {
            serviceName = SERVICES_ENUM.MY_ORG;
            nodeName = `${result.fields.labelKey}`;
        }

        Object.keys(result.fields).forEach(fieldKey => {
            const isIncluded = isSubstring(term, result.fields[fieldKey]);
            if(isIncluded) {
                const fieldKeyTranslation = TranslationUtil.getTranslation(translations, fieldKey);
                const option = {
                    id: result.index.itemId,
                    value: result.index.itemId,
                    label: `${fieldKeyTranslation}: ${result.fields[fieldKey]}`,
                    icon: result.index.icon,
                    type: nodeName,
                    index: result.index,
                    serviceName,
                };
                if(fieldKey !== 'clientId')
                    options.push(option);
            }            
        });

        if (!options.length && result.fields.itemType === 'user') {
            const isIncluded = isSubstring(term, `${result.fields.firstName} ${result.fields.lastName}`);

            if(isIncluded) {
                options.push({
                    id: result.index.itemId,
                    parentGroupId: result.index.parentGroupId,
                    value: result.index.itemId,
                    label: `${result.fields.firstName} ${result.fields.lastName}`,
                    icon: result.index.icon,
                    type: nodeName,
                    index: result.index,
                    serviceName
                });
            }
        }
    });

    return options;
}

function isSubstring(term, value) {
    if(term && value){
        if(term.split(' ').length > 1){
            return value.toLowerCase().indexOf(term.toLowerCase()) !== -1;
        }
        const valueWords = value.split(' ');
        valueWords.some((word) => word.toLowerCase().startsWith(term.toLowerCase()));
        return valueWords.some((word) => word.toLowerCase().startsWith(term.toLowerCase()));
    }
    return false;
}

class HomeSearcher {

    static async search(term, translations){
        const json = {
            term, 
            typeOrder: ['user', 'asset', 'group'],
        };
        const searchCriteria = IndexParser.toSearchCritiria(json);
        const searchResults = await searchUtil.searchFor(searchCriteria);
        const options = getOptions(searchResults, term, translations);
        return options;
    }
    
}

export default HomeSearcher;
