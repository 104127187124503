const styles = theme => ({
    root: {
        height: theme.spacing.unit * 4,
        flexGrow: 1,
        padding:theme.spacing.unit * 2,
        paddingTop: `${theme.spacing.unit}px !important`,
        marginBottom: `${theme.spacing.unit}px`,
    },
    input: {
        // padding: theme.spacing.unit,
        height: '40px',
        backgroundColor: '#fff',
        padding: '1px 60px 1px 20px',
        boxSizing: 'border-box',
        backgroundImage: 'url(../axstandard/icons/ax-search-small.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 75%',
        backgroundPosition: 'calc(100% - 15px) center',
        borderRadius: '20px',
        '&::placeholder': {
            color: 'rgba(4,110,172,0.5)',
            opacity: 1,
        }
    },

    notchedOutline: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgba(4,110,172,0.5) !important',
        borderRadius: '20px',
    },
    
    container: {
        position: 'relative',
        padding:0
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1000,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        padding:0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionIcon: {
        width: '25px',
        height: '25px',
        marginRight: '5px',
        marginTop: '-2px',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    serchTextBox: {
        display: 'flex',
        alignItems: 'center',
    },
    mobileMode: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 100000,
    },
    searchMobileButton: {
        position: 'relative',
        zIndex: 2,
    },
    mobileSelect: {
        '@media(max-width: 800px)': {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1,
            boxSizing: 'border-box',
            paddingTop: '120px',
            paddingLeft: '40px',
            paddingRight: '40px',
        }
    },
    mobileSelectInner: {
        '@media(max-width: 800px)': {
            backgroundColor: '#fff',
        }
    }
});

export default styles;