const styles = theme => ({
    tabBar: {
        padding: 0,// theme.spacing.unit * 2,
        boxShadow: 'none',
        position: 'relative',
        zIndex: 1,
        marginLeft: theme.spacing.unit * 4,
        marginRight: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit + 4,
        paddingRight: theme.spacing.unit + 4,
        background: 'none',
        '@media(max-width: 800px)': {
            zIndex: 20,
            width: '100%',
            margin: 0,
            padding: 0,
        }
    },
    tabBarInner: {
        overflow: 'hidden',
        flexWrap: 'nowrap',
        paddingTop: theme.spacing.unit / 2
    },
    tabGridItem: {
        whiteSpace: 'nowrap',
        marginRight: '20px',
        '@media(max-width: 800px)': {
            margin: 0,
        }
    },
    tabIcon: {
        display: 'inline-block',
        borderWidth: '2px',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        borderStyle: 'solid',
        margin: '-2px 10px -2px 5px',
        backgroundColor:'#FFF'
    },
    tabIconText : {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    arrowLeftButton : {
        position:'absolute',
        left: -3 * theme.spacing.unit,
        top: 2.5 * theme.spacing.unit,
        height: 2 * theme.spacing.unit,
        width: 2 * theme.spacing.unit,
        backgroundColor: '#07a0e3',
        color: '#fff',
    },
    arrowLeftIcon: {
        position:'absolute'
    },
    arrowRightButton : {
        position:'absolute',
        right: -3 * theme.spacing.unit,
        top: 2.5 * theme.spacing.unit,
        height: 2 * theme.spacing.unit,
        width: 2 * theme.spacing.unit,
        backgroundColor: '#07a0e3',
        color: '#fff',
    },
    arrowRightIcon: {
        position:'absolute'
    },


    mobileTabBar: {
        position: 'relative',
        border: '1px solid #eee',  
    },
    selectedItem: {
        padding: '0 20px',
        height: '50px',
        background: '#fff',
        display: 'flex',
        alignItems: 'center', 
        zIndex: 2,
        position: 'relative',
    },
    mobileTabsList: {
        position: 'absolute',
        top: '100%',
        width: '100%',
        left: 0,
        border: '1px solid #eee',
        boxShadow: '5px 3px 5px rgba(0, 0, 0, 0.4)',
        zIndex: 2,
        boxSizing: 'border-box',
        maxHeight: '450px',
        overflow: 'auto',
    },
    mobileSummaryIcon: {
        width: '30px',
        height: '35px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginRight: '10px',
    },
    mobileTabArrow: {
        position: 'absolute',
        right: '20px',
        content: '',
        display: 'block',
        borderTop: '2px solid #ccc',
        borderLeft: '2px solid #ccc',
        width: '10px',
        height: '10px',
        transition: 'transform 300ms, top 300ms',
    },
    opendArrow: {
        transform: 'rotate(45deg)',
        top: '27px',
    },
    closedArrow: {
        transform: 'rotate(225deg)',
        top: '16px',
    },
    mobileTabListOverlay: {
        position: 'fixed',
        zIndex: 1,
        background: 'rgba(0, 0, 0, 0.2)',
        right: 0,
        bottom: 0,
        left: 0,
        top: 0,
    },
});

export default styles;
