import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from './styles';
import Title from '../../../core/components/Title';
import TooltipButton from '../../../core/components/TooltipButton';
import ServiceTile from '../../components/ServiceTile';
import TranslationUtil from '../../../../utils/TranslationUtil';
import { RoleService } from '../../../../utils/RoleService';
import {SERVICES_ENUM} from "../../../../constants";

const mapState = state => ({
    state: state.home,
});

const mapDispatch = dispatch => ({
    openService: service => dispatch.core.openService(service),
    toggleShowAllServicesState: showAll => dispatch.home.toggleShowAllServicesState(showAll),
});

const BREAK_POINTS = {
    XS: 0,
    SM: 600,
    MD: 960,
    LG: 1280,
    XL: 1920,
};

/**
 * This widget displays all the available services for the user.
 */
class UserServiceAreaWidget extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            breakPoint: null,
            serviceListHeight: 'auto',
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.registerResizeEvent);
        this.registerResizeEvent();
    }

    componentDidUpdate() {
        this.calculateListHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.registerResizeEvent);
    }

    registerResizeEvent = () => {
        const { innerWidth } = window;
        let bp = null;
        if (innerWidth > BREAK_POINTS.XL) {
            bp = BREAK_POINTS.XL;
        } else if (innerWidth > BREAK_POINTS.LG) {
            bp = BREAK_POINTS.LG;
        } else if (innerWidth > BREAK_POINTS.MD) {
            bp = BREAK_POINTS.MD;
        } else if (innerWidth > BREAK_POINTS.SM) {
            bp = BREAK_POINTS.SM;
        } else if (innerWidth > BREAK_POINTS.XS) {
            bp = BREAK_POINTS.XS;
        }
        this.setState({
            breakPoint: bp
        });
    }

    getLineCountServices = () => {
        const { services = [] } = this.props;
        const { breakPoint } = this.state;
        
        switch (breakPoint) {
        case BREAK_POINTS.XL : {return 6; }
        case BREAK_POINTS.LG : {return 6; }
        case BREAK_POINTS.MD : {return 4; }
        case BREAK_POINTS.SM : {return 3; }
        case BREAK_POINTS.XS : {return 2; }
        default : { return services.length; }
        }
    }


    goToService = (service) => {
        if (service.serviceName === 'myPtt') {
            return this.props.openService(service);
        }
        if (service.serviceExternalPath) {
            return window.open(service.serviceExternalPath);
        }
        return this.props.openService(service);
    }

    renderServices = () => {
        const { classes, services = [], translations, state } = this.props;
        const showAll = state && state.showAllServices;
        const lineCount = this.getLineCountServices();

        let servicesToDisplay = services;
        if (!showAll && RoleService.instance.isUserAdmin()) {
            servicesToDisplay = services.slice(0, lineCount);
        }

        return servicesToDisplay.map((service, index) => {

            return (
                <Grid key={index} className={classes.tileCell} item xs={window.innerWidth <= 400 ? 12 : 6} sm={4} md={3}
                      lg={2} xl={2}>
                    <ServiceTile
                        label={TranslationUtil.getTranslation(translations, service.translatedLabel)}
                        icon={service.largeIcon}
                        name={service.name}
                        onClick={() => this.goToService(service)}
                        disabledButton={!translations}
                    />
                </Grid>
            );
        });
    }

    toggleShowState = () => {
        const { state, toggleShowAllServicesState } = this.props;
        toggleShowAllServicesState(!state.showAllServices);
        this.getLineCountServices();
    }

    calculateListHeight = () => {
        const { serviceListHeight } = this.state;
        if (this.containerRef) {
            if (this.containerRef.offsetHeight !== serviceListHeight) {
                this.setState({
                    serviceListHeight: this.containerRef.offsetHeight
                });
            }
        }
    }

    /**
     * This is where we render the widget.
     */
    render() {

        // classes are injected with the "withStyles" function. See export at the bottom of the file.
        const { classes, translations, state } = this.props;
        const { serviceListHeight } = this.state;
        const showAll = state && state.showAllServices;

        return (
            <div className={classes.root} style={{ 'height': window.innerHeight - 270 }}>
                <div className={classes.titleWrapper}>
                    <Title>{TranslationUtil.getTranslation(translations, "services_widget_title")}</Title>
                </div>
                <div className={classes.servicesListWrapper}>
                    <div style={{height: `${serviceListHeight}px`}} className={classNames(classes.servicesBox, showAll && classes.servicesBoxOpen)}>
                        <div ref={(containerRef) => {this.containerRef = containerRef;}}>
                            <Grid container>
                                {this.renderServices()}
                            </Grid>
                        </div>
                    </div>
                    {RoleService.instance.isUserAdmin() && (
                        <div className={classes.showAllButtonContainer}>
                            <TooltipButton 
                                onClick={this.toggleShowState}
                                customClass={classNames(classes.moreButton, showAll && classes.showLess)}
                                title={TranslationUtil.getTranslation(translations, showAll ? "show_less_button" : "show_more_button")}
                            />
                            {/* <button className={classes.showAllButton} onClick={this.toggleShowState}>
                            {showAll ? TranslationUtil.getTranslation(translations, "show_less_button") : TranslationUtil.getTranslation(translations, "show_more_button")}
                        </button> */}
                        </div>
                    )}
                </div>
            </div>

        );
    }

}

UserServiceAreaWidget.propTypes = {
    openService: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    services: PropTypes.array,
    translations: PropTypes.object,
    state: PropTypes.object,
    toggleShowAllServicesState: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(withStyles(styles)(UserServiceAreaWidget));