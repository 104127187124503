import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import styles from './styles';

/**
 * Diplays the tile of a given service on the home screen
 */
class ServiceTile extends React.Component {

    render () {
        const inlineStyle = {
            backgroundImage : `url('/axstandard/icons/ax-${  this.props.icon  }-service.svg')`
        };

        const {classes} = this.props;

        return (
            <div className={classes.serviceTitleItem} >
                <Button disabled={this.props.disabledButton} onClick={this.props.onClick} variant="outlined" className={classes.button} style={inlineStyle}>
                    <span className={classes.label}>{this.props.label}</span>
                </Button>
            </div>
        );
    }
    
}

ServiceTile.propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    disabledButton: PropTypes.bool
};

export default withStyles(styles)(ServiceTile);