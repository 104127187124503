import React from 'react';
import PropTypes from 'prop-types';

const AlarmSound = (props) => {

    return (
        <audio loop autoPlay muted={props.muted}>
            <source src="../music/sound.mp3" type="audio/mpeg" />
            <track kind="captions" />
        </audio>
    );

};

AlarmSound.propTypes = {
    muted: PropTypes.bool.isRequired,
};

export default AlarmSound;