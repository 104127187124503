import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/*
component.props.perms is an array of arrays of strings :
    [['perm1', 'perm2'],['perm2', 'perm3', '!perm4']]
in this example, we return true if we have permissions 
    'perm1' AND 'perm2' OR 'perm2' AND 'perm3' BUT NOT 'perm4'
*/

const mapDispatch = dispatch => ({});


function isUserAllowed(userPermissions, componentPerms) {
    
    if(!componentPerms || componentPerms.length === 0 || componentPerms[0].length === 0) {
        return true;
    }

    if(!userPermissions) {
        return false;
    }

    for(let p = 0; p < componentPerms.length; p++) {
        const andGroup = componentPerms[p];
        let allowedByGroup = true;
        for(let g = 0; g < andGroup.length; g++) {
            const perm = andGroup[g];
            const inverted = perm.indexOf('!') >= 0;
            
            const userPerm = userPermissions[perm];
            
            allowedByGroup = userPerm || inverted;

            if(!allowedByGroup) {
                break;
            }


        }
        
        // if one group allows the access then we don't need to check the others
        if(allowedByGroup) {
            return true;
        }

    }

    return false;

}

class HasPermissions extends React.Component {

    componentDidMount() {
        if(this.props) {
            // this.props.loadRolesAndPermissionsAsynch();
        }
    }

    render() {
        const userPermissions = this.props ? this.props.userPermissions : null;
        const componentPermissions = this.props ? this.props.perms : null;
  
        if(isUserAllowed(userPermissions, componentPermissions)) {
            return this.props.children;
        } 
        return '';
    }

}

HasPermissions.propTypes = {
    children: PropTypes.any.isRequired,
    perms: PropTypes.any,
    userPermissions: PropTypes.any,
    // loadRolesAndPermissionsAsynch: PropTypes.func.isRequired
};

export default connect(null, mapDispatch)(HasPermissions);