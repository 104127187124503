import React, {Component} from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {init} from '@rematch/core';
import {Provider} from 'react-redux';
import { ToastContainer } from 'react-toastify';
import CoreScreen from './services/core/screens/CoreScreen';
import * as models from './services/core/coreModel';
import SaveStateMiddleware from './utils/middleware/SaveStateMiddleware';
import HasPermissions from './services/core/components/HasPermissions';
import VERSION from './version';
import ErrorBoundary from './services/core/components/ErrorBoundary';
import StoreUtils from './utils/StoreUtils';
import {subdomain} from "./constants";

console.log(`Starting up AXURIS version ${VERSION} | subdomain: ${subdomain}`);

export const store = init({
    models,
    redux: {
        middlewares: [SaveStateMiddleware]
    }
});

StoreUtils.setStore(store);

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <div className="App">
                    <ToastContainer/>
                    <ErrorBoundary>
                        <HasPermissions>
                            <CoreScreen/>
                        </HasPermissions>
                    </ErrorBoundary>
                </div>
            </Provider>
        );
    }
}

export default App;
