const styles = theme => ({
    primary: {
        main: '#F00'
    },
    root: {
        flexGrow: 1,
        paddingTop:theme.spacing.unit * 5,
        paddingLeft:theme.spacing.unit * 2,
        paddingRight:theme.spacing.unit * 2
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

export default styles;