import TranslationMgr from '../../utils/TranslationManager';

const model = {
    // initial state
    name:'home',
    state: {
        translations: null,
        showAllServices: false,
    },
    reducers: {

        setupTranslations(state, translations) {
            return { ...state, translations };
        },

        toggleShowAllServicesState(state, payload) {
            if (typeof payload === 'boolean') {
                return { ...state, showAllServices: payload };
            }
            return { ...state };
        }

    },
    effects: (dispatch) => ({ 

        async setupTranslationsAsync(lang) {           
            const dictionnary = await TranslationMgr.getDictionnary('home', lang);
            dispatch.home.setupTranslations(dictionnary);
        },

    })
};

export default model;