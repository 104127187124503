import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import { mdiHome } from '@mdi/js';
import PropTypes from 'prop-types';
import detectBrowserLanguage from 'detect-browser-language';
import TabContainer from '../components/TabContainer';
import Spinner from '../components/Spinner';
import HomeScreen from '../../home/screens/HomeScreen';
import styles from './styles';
import TranslationsUtil from '../../../utils/TranslationUtil';
import AlarmSound from '../components/AlarmSound';
import { RoleService } from '../../../utils/RoleService';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgba(7, 160, 227, 1)'
        },
    },
});


const mapState = state => ({
    state: state.core,
});

const mapDispatch = (dispatch) => ({
    loadAvailableServicesAsync: (lang) => dispatch.core.loadAvailableServicesAsync(lang),
    loadRolesAsync: () => dispatch.core.loadRolesAsync(),
    fetchPermissionsAsync: () => dispatch.core.fetchPermissionsAsync(),
    fetchAlarmsAsync: (alarmsQuery) => dispatch.core.fetchAlarmsAsync(alarmsQuery),
});

const servicesContent = {};

class MainScreen extends React.Component {
    
    state = {
        muted: false
    }

    constructor(props) {
        super(props);
        RoleService.instance.setRolesFromJwt(this.props.state.jwtToken);
    }

    async componentDidMount() {
        const lang = detectBrowserLanguage(); // TODO REPLACE WITH USER LANGUAGE FROM PROFILE
        await this.props.loadRolesAsync();
        await this.props.fetchPermissionsAsync();
        await this.props.loadAvailableServicesAsync(lang);
        await this.props.fetchAlarmsAsync({onlyActiveAlarms: this.props.state.displayOnlyActive});

        this.fetchInterval = setInterval(() => {
            const alarmsQuery = {
                onlyActiveAlarms: this.props.state.displayOnlyActive
            };
            this.props.fetchAlarmsAsync(alarmsQuery);
        }, 20000); 
        this.checkAlarmsMuted();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.state.displayOnlyActive !== this.props.state.displayOnlyActive) {
            const alarmsQuery = {
                onlyActiveAlarms: this.props.state.displayOnlyActive
            };

            this.props.fetchAlarmsAsync(alarmsQuery);
        }
        this.checkAlarmsMuted();
    }

    componentWillUnmount() {
        clearInterval(this.fetchInterval);
    }

    checkAlarmsMuted = () => {
        const { alarms } = this.props.state;

        if(alarms.some(alarm => alarm.muted === false)) {
            this.state.muted && this.setState({muted: false});
        } else {
            !this.state.muted && this.setState({muted: true}); 
        }
    }

    deriveServiceScreen = (service) => {
        const serviceScreenName = `${service.serviceName.substring(0, 1).toUpperCase() + service.serviceName.substring(1)}Screen`;
        const Screen = lazy(() => import(`../../${service.serviceName}/screens/${serviceScreenName}`));
        const content = (
            <Suspense fallback={<Spinner />}>
                <Screen />
            </Suspense>
        );
        servicesContent[service.serviceName] = content;
        return content;
    }

    renderTabContents = () => {
        const {services, translations} = this.props.state;
        const tabs = [];
    
        tabs[0] = {
            tabId: 'home',
            label: TranslationsUtil.getTranslation(translations, 'home'),
            selected: true,
            closable: false,
            content: <HomeScreen />,
            icon: 'ax_logo_x.svg'
        };
    
        const dynamicTabs = services && services.map((service) => (
            {
                tabId: service.serviceName,
                label: TranslationsUtil.getTranslation(translations, service.translatedLabel),
                content: servicesContent[service.serviceName] || this.deriveServiceScreen(service),
                selected: service.selected,
                closable: true,
            }
        ));
    
        for (let i = 0; i < dynamicTabs.length; i++) {
            if (dynamicTabs[i].selected) {
                tabs[0].selected = false;
                break;
            }
        }
    
        return [...tabs, ...dynamicTabs];
    }

    render() {
        const { classes } = this.props;
        const { alarms } = this.props.state;
        const tabs = this.renderTabContents();

        return (
            <MuiThemeProvider theme={theme}>
                <div className={classes.root}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <TabContainer tabContents={tabs} alarms={alarms} />
                            <AlarmSound muted={this.state.muted} />
                        </Grid>
                        <Grid item xs={11} />
                    </Grid>
                </div>
            </MuiThemeProvider>
        );
    }
}


MainScreen.propTypes = {
    state: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    loadAvailableServicesAsync: PropTypes.func.isRequired,
    loadRolesAsync: PropTypes.func.isRequired,
    fetchPermissionsAsync: PropTypes.func.isRequired,
    fetchAlarmsAsync: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(withStyles(styles)(MainScreen));