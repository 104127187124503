class Index {
    constructor(uuId, itemType) {
        this.itemId = uuId;
        this.itemType = itemType;
    }

    getId(){
        return this.itemId;
    }

    getItemType(){
        return this.itemType;
    }

    getParentGroupId(){
        return this.parentGroupId;
    }

    getSubTypeId() {
        return this.subTypeId;
    }

    setParentGroupId(parentGroupId){
        this.parentGroupId = parentGroupId;
    }

    setSubTypeId(subTypeId) {
        this.subTypeId = subTypeId;
    }

    getSubType(){
        return this.subType;
    }

    setSubType(subType){
        this.subType = subType;
    }
 
    getVersion(){
        return this.version;
    }

    setVersion(version){
        this.version = version;
    }
}

export default Index;