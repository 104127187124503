import detectBrowserLanguage from 'detect-browser-language';

class TranslationsUtil {

    /**
     * Get translation by key.
     * @param {object: {key, value: string}} translations 
     * @param {string} key 
     */
    static getTranslation(translations, key){
        if(translations && translations[key])
            return translations[key];
        return key; 
    }
    
    /**
     * Get cuurent translation list for service by service name and current language.
     * @param {object} dictionaries 
     * @param {string} lang 
     * @param {string} serviceName 
     */
    static getCurrentTranslations(dictionaries, lang, serviceName) {
        const translations = {};
        dictionaries.translationData && Object.keys(dictionaries.translationData).forEach(key => {
            if(dictionaries.translationData[key].name === serviceName){
                const translationKeys = dictionaries.translationData[key].rows;
                let translationValue;
                Object.keys(translationKeys).forEach(translationKey => {
                    const { data } = translationKeys[translationKey];
                    if (data[lang] && data[lang].checked) {
                        translationValue = data[lang].value;
                    } else {
                        translationValue = data[translationKeys[translationKey].defaultLang].value;
                        console.log('Default language applied.');
                    }
                    // const translationValue = data[lang] ? data[lang].value : data[translationKeys[translationKey].defaultLang].value;
                    translations[translationKey] = translationValue;
                });
            }
        });

        return translations;
    }

    /**
     * Get default language.
     */
    static getDefaultLanguage() {
        let lang = detectBrowserLanguage(); // TODO: In the future we will selecta language from UI.
        lang = lang.toLowerCase().split('-');
        return lang[0];
    }

    /**
     * TODO: need delete after all translation will changed.
     */
    static setupDictionary(currentDictionary, lang) {
        const dictionnary = {};

        currentDictionary && currentDictionary.rows && Object.keys(currentDictionary.rows).forEach(translationRow => {
            Object.keys(currentDictionary.rows[translationRow].data).forEach(languageData => {
                if(currentDictionary.rows[translationRow].data[languageData].lcid === lang) {
                    dictionnary[translationRow] = currentDictionary.rows[translationRow].data[languageData].value;
                }
            });
        });

        return dictionnary;
    }

    /**
     * TODO: need delete after all translation will changed.
     */
    static getDictionaryByName(dictionaries, name) {
        let dictionary = {};

        dictionaries && Object.keys(dictionaries.translationData).forEach(dict => {
            if(dictionaries.translationData[dict].name === name) {
                dictionary = {...dictionaries.translationData[dict]};
            }
        });

        return dictionary;
    }
}

export default TranslationsUtil;