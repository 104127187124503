class SearchCriteria {
    constructor(term, itemTypeOrderList) {
        this.searchTerm = term;
        this.itemTypeOrder = itemTypeOrderList;
    }

    getSearchTerm() {
        return this.searchTerm;
    }

    getTypeOrder() {
        return this.itemTypeOrder;
    }

    getSubTypeOrder() {
        return this.itemSubTypeOrder;
    }
    
    setSubTypeOrder(subTypeOrderList) {
        this.itemSubTypeOrder = subTypeOrderList;
    }

    getLimit() {
        return this.limit;
    }
    
    setLimit(limit) {
        this.limit = limit;
    }
}

export default SearchCriteria;