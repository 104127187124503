import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import styles from './styles';


function ClosableTab(props) {

    const { classes, alarms } = props;

    const tabClass = [classes.tab];
    const [activeColor, setActiveColor] = useState(false);
    let colorInterval = null;

    useEffect(() => {
        return () => {
            clearTimeout(colorInterval);
        };
    }, []);

    useEffect(() => {
        colorInterval = setTimeout(() => {
            setActiveColor(!activeColor);
        }, 1000);
        return () => {
            clearTimeout(colorInterval);
        };
    }, [activeColor]);

    let skewedSideClass = classes.tabSkewedBorder;

    if (props.selected) {
        tabClass[tabClass.length] = classes.tabSelected;
        skewedSideClass = classes.tabSkewedBorderSelected;
    }

    if (props.closable) {
        tabClass[tabClass.length] = classes.tabClosable;
    }


    const closeButton = props.closable ? (
        <IconButton onClick={(event) => props.onCloseTab(event)} variant="outlined" size="small" className={classes.closeButton}>
            <CloseIcon className={classes.closeButtonIcon} variant="outlined" size="small" />
        </IconButton>
    ) : '';

    const iconUrl = 'url(\'/' + props.icon + '\')';   
    const icon = props.icon ? <div className={classes.tabIconWrapper}><div className={classes.tabIcon} style={{backgroundImage: iconUrl}} /></div> : '';

    return (
        <div>
            <Paper style={{background: props.tabId === 'myAlarms' && alarms.some(alarm => alarm.status === 'ACTIVE') && alarms.length !== 0 && activeColor && !props.selected && '#E22011' }} onClick={props.onClick} className={tabClass.join(' ')}>
                <span>
                    {icon}
                    {props.children}
                </span>
                {closeButton}
                <span style={{background: props.tabId === 'myAlarms' && alarms.some(alarm => alarm.status === 'ACTIVE') && alarms.length !== 0 && activeColor && !props.selected && '#E22011' }} className={skewedSideClass} />
            </Paper>
        </div>
    );


}

ClosableTab.propTypes = {
    classes: PropTypes.object.isRequired,
    closable: PropTypes.bool.isRequired,
    selected: PropTypes.bool,
    onCloseTab: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    children: PropTypes.any.isRequired,
    tabId: PropTypes.string,
    alarms: PropTypes.array,
};

export default withStyles(styles)(ClosableTab);