import Index from './Index';
import IndexRequest from './IndexRequest';
import SearchCritiria from './SearchCriteria';

class IndexParser {

    static toIndexRequest(json) {
        const index = new Index(json.id, json.itemType);
        json.parentGroupId && index.setParentGroupId(json.parentGroupId);
        json.subItemType && index.setSubType(json.subItemType);
        json.subTypeId && index.setSubTypeId(json.subTypeId);
        json.subItemTypeId && index.setSubTypeId(json.subItemTypeId);
        json.version && index.setVersion(json.version);
        const indexRequest = new IndexRequest(index);
        json.fields && Object.keys(json.fields).forEach(key => {
            indexRequest.addField(key, json.fields[key]);
        });

        return indexRequest;
    }

    static toSearchCritiria(json) {
        const searchCriteria = new SearchCritiria(json.term, json.typeOrder);
        json.subTypeOrder && searchCriteria.setSubTypeOrder(json.subTypeOrder);
        json.limit && searchCriteria.setLimit(json.limit);

        return searchCriteria;
    }

    static fromSearchResult(searchResult) {
        const json = {
            index: searchResult.getIndex(),
            fields: searchResult.getFields()
        };

        return json;
    }
}
export default IndexParser;