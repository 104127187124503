const styles = theme => ({
    serviceTitleItem: {
        position: 'relative',
        textAlign: 'center',
        paddingBottom: '25px',
    },
    button: {
        display: 'inlince-block',
        padding:theme.spacing.unit * 2,
        width: '50%',
        height: '100%',
        backgroundPosition: '10px -2px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95% 95%',
        paddingTop: '45%',
        minWidth:'100px',
        minHeight:'100px',
        border:'none',
        borderRadius:'18px',
        position: 'static',
    },
    label : {
        position:'absolute',
        left: 0,
        bottom: 0,
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    }
});

export default styles;