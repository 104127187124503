import axios from "axios";
import jwtDecode from "jwt-decode";
import StoreUtils from "./StoreUtils";

let jwtToken = null;

async function authenticateAsync(url, credentials) {
    let response;
    await axios.post(url, credentials).then((res) => {
        response = res;
        jwtToken = response.headers["Authorization"];
        jwtToken = jwtToken ? jwtToken : response.headers["authorization"];
    });
    return response;
}

function getConfig() {
    const config = {
        headers: {
            Authorization: jwtToken,
        },
    };
    return config;
}

async function postAsync(url, payload) {
    const config = getConfig();
    validateToken();
    const response = await axios.post(url, payload, config);
    validateResponse(response);
    return response;
}

async function putAsync(url, payload) {
    const config = getConfig();
    validateToken();
    const response = await axios.put(url, payload, config);
    validateResponse(response);
    return response;
}

async function deleteAsync(url) {
    const config = getConfig();
    validateToken();
    const response = await axios.delete(url, config);
    validateResponse(response);
    return response;
}

async function getAsync(url, payload) {
    const config = getConfig();
    validateToken();
    const keys = payload ? Object.keys(payload) : [];
    let params = "";

    for (let k = 0; k < keys.length; k++) {
        params += keys[k] + "=" + payload[keys[k]] + "&";
    }

    const urlWithParams = params ? url + "?" + params : url;
    let response = await axios.get(urlWithParams, config);
    validateResponse(response);

    response = response ? response : {};

    return response;
}

function getResourceAsDataUriAsync(url, mimeType, callback) {
    const config = getConfig();

    config.responseType = "arraybuffer";
    axios.get(url, config).then((res) => {
        let result = Buffer.from(res.data, "binary").toString("base64");
        result = "data:" + mimeType + ";base64," + result;
        callback(result);
    });

}

function validateToken() {
    if (jwtToken === null) {
        return;
    }
    const decodedToken = jwtDecode(jwtToken);
    const tokenExpDate = decodedToken.exp;
    const currentDate = Math.floor(Date.now() / 1000);
    const state = StoreUtils.store.getState();

    if (currentDate >= tokenExpDate && state.core.authenticated) {
        StoreUtils.store.dispatch.core.logout();
        StoreUtils.store.dispatch.core.setError('Your session has expired. Please login again');
        setTimeout(() => {
            StoreUtils.store.dispatch.core.setError(null);
        }, 5000);
    }
}

function validateResponse(response) {
    const state = StoreUtils.store.getState();

    if (response.status === 403 && state.core.authenticated) {
        StoreUtils.store.dispatch.core.logout();
        StoreUtils.store.dispatch.core.setError('Your session has expired. Please login again');
        setTimeout(() => {
            StoreUtils.store.dispatch.core.setError(null);
        }, 1500);
    }
}

async function reAuthenticateAsync(url) {
    const response = getAsync(url, {})();
    return response;
}

class CommunicationAgent {
    getJwtToken() {
        return jwtToken;
    }

    setJwtToken(token) {
        jwtToken = token;
    }

    reAuthenticate() {
        return reAuthenticateAsync();
    }

    authenticate(url, credentials) {
        return authenticateAsync(url, credentials);
    }

    post(url, payload) {
        return postAsync(url, payload);
    }

    put(url, payload) {
        return putAsync(url, payload);
    }

    get(url, payload) {
        return getAsync(url, payload);
    }

    delete(url) {
        return deleteAsync(url);
    }

    getResourceAsDataUri(url, mimeType, callback) {
        return getResourceAsDataUriAsync(url, mimeType, callback);
    }
}

// Singleton pattern : only one agent at a time.
const instance = new CommunicationAgent();

export default instance;
