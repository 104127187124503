const ls = require('local-storage');

const saveStateMiddleware = store => next => action => {

    const nextAction = next(action);


    if (action.type !== 'core/checkLastInteractionAsync' &&
    action.type !== 'core/loadSavedState' &&
    store.getState().core.authenticated) {

        store.getState().core.lastInteraction = Date.now();

        const parts = action.type.split('/');

        if(parts.length >= 2 && store.getState()[parts[0]]) {
            ls.set('axuris.' + parts[0], store.getState()[parts[0]]);
        }
    }

    return nextAction;


};

export default saveStateMiddleware;
