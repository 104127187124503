import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Search, Close } from '@material-ui/icons';
import styles from './styles';
import TooltipButton from '../../../core/components/TooltipButton';
import { SERVICES_ENUM, ITEM_ICON } from '../../../../constants';

const mapState = (state) => ({
    isMobileMode: state.core.isMobileMode
});

class IntegrationAutosuggest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            single: '',
            suggestions: [],
            isInputHidden: props.isMobileMode,
        };
    };

    componentDidMount() {
        const { isMobileMode } = this.props;
        this.setState({
            isInputHidden: isMobileMode,
        });
    }

    componentDidUpdate() {
        this.rollBakcState();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isMobileMode && !this.props.isMobileMode) {
            this.setState({
                isInputHidden: true,
            });
        }
    }

    rollBakcState = () => {
        if (!this.props.isMobileMode && this.state.isInputHidden) {
            this.setState({
                isInputHidden: false,
            });
        }
    }

    renderInputComponent = (inputProps) => {
        const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    
        return (
            <TextField
                fullWidth
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                        notchedOutline: classes.notchedOutline,
                    },
                }}
                {...other}
            />
        );
    }

    handleSuggestionClick = (suggestion) => {
        const { onSuggestionClick, isLocator, isMobileMode } = this.props;
        if(isLocator){
            onSuggestionClick(suggestion);
        }
        else{
            onSuggestionClick(suggestion.index || suggestion.id);
        }
        if (isMobileMode) {
            this.setState({
                isInputHidden: true,
            });
        }
    }
    
    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const { classes } = this.props;
        const aLabel = `${suggestion.label  }${suggestion.type ? ` (${suggestion.type})` : ''}`;
        const matches = match(aLabel, query);
        const parts = parse(aLabel, matches);
        const iconUrl = this.getIconUrl(suggestion.serviceName);

        return (
            <MenuItem
                selected={isHighlighted} 
                onClick={() => {this.handleSuggestionClick(suggestion);}}
                component="div"
            >
                <div className={classes.serchTextBox}>
                    {iconUrl && <img className={classes.suggestionIcon} src={iconUrl} alt={iconUrl} />}
                    {parts.map((part, index) =>
                        part.highlight ? (
                            <span key={String(index)} style={{ fontWeight: 500, marginLeft: '5px', color: 'rgba(4,110,172,1)'}}>
                                {part.text}
                            </span>
                        ) : (
                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </strong>
                        ),
                    )}
                </div>
            </MenuItem>
        );
    }

    getIconUrl = (serviceName) => {
        let url = null;
        Object.keys(SERVICES_ENUM).forEach((item) => {
            if (SERVICES_ENUM[item] === serviceName) {
                url = ITEM_ICON[item];
            };
        });
        return url;
    }
    
    getSuggestionValue = (suggestion) => {
        return `${suggestion.label}${suggestion.type ? ` (${suggestion.type})` : ''}`;
    }


    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        this.setState({
            [name]: newValue,
        });
    };


    async handleSuggestionsFetchRequested ({ value }){
        const suggestions = await this.props.searchAsync(value);
        this.setState({
            suggestions
        });
    };

    onShowMobileSearch = () => {
        this.setState({
            isInputHidden: !this.state.isInputHidden,
        });
    }

    renderSearchContent = () => {

        const { classes, isMobileMode, disabled } = this.props;
        const { isInputHidden } = this.state;

        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested.bind(this),
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion: this.renderSuggestion,
        };

        const wrapperClass = isMobileMode ? classes.mobileMode : classes.root;

        return (
            <div className={wrapperClass}>
                { isMobileMode && (
                    <div className={classes.searchMobileButton}>
                        <TooltipButton 
                            onClick={this.onShowMobileSearch} 
                        >
                            {isInputHidden ? <Search style={{color: '#fff'}} /> : <Close style={{color: '#fff'}} />}
                        </TooltipButton>
                    </div>
                )}
                { !isInputHidden &&  (
                    <div className={classes.mobileSelect}>
                        <div className={classes.mobileSelectInner}>
                            <Autosuggest
                                {...autosuggestProps}
                                inputProps={{
                                    variant: 'outlined',
                                    classes,
                                    placeholder: disabled ? 'search_unavailable' : this.props.placeholder,
                                    value: this.state.single,
                                    onChange: this.handleChange('single'),
                                    disabled
                                }}
                                theme={{
                                    container: classes.container,
                                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                    suggestionsList: classes.suggestionsList,
                                    suggestion: classes.suggestion,
                                }}
                                renderSuggestionsContainer={options => (
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                )}
                                onSuggestionSelected={(item, { suggestion }) => {
                                    this.handleSuggestionClick(suggestion);
                                }}
                            />
                        </div>
                    </div>
                )}
              
             
            </div>
        );
    }

    render() {
        const { isMobileMode } = this.props;
        // const el = document.createElement('div');
        const el = document.querySelector('#root');
        const searchFiled = this.renderSearchContent();
        if (isMobileMode) {
            return ReactDOM.createPortal(
                searchFiled,
                el,
            );
        } 
        return searchFiled;
        
    }
}


IntegrationAutosuggest.propTypes = {
    searchAsync: PropTypes.func,
    classes: PropTypes.object.isRequired,
    suggestions: PropTypes.array,
    placeholder: PropTypes.any.isRequired,
    state: PropTypes.object,
    onSuggestionClick: PropTypes.func,
    isLocator: PropTypes.bool,
    isMobileMode: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default connect(mapState)(withStyles(styles)(IntegrationAutosuggest));
