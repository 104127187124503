import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from "../Button";

const mapState = state => ({
    state: state.core
});

const mapDispatch = dispatch => ({
    catchErrorAsync: errorWithInfo => dispatch.core.catchErrorAsync(errorWithInfo),
    closeService: serviceName => dispatch.core.closeService(serviceName),
    logout: () => dispatch.core.logout()
});

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
    },

    paper: {
        ...theme.mixins.gutters(),
        position: 'absolute',
        top: '40%',
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class ErrorBoundary extends React.Component {

    constructor(props){
        super(props);
        this.state ={
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    handleBackClick = () => {
        let selectedService;
        this.props.state.services.forEach(service => {
            if(service.selected){
                selectedService = service.serviceName;
            };
        });
        if(selectedService){
            this.props.closeService(selectedService);
        }
        else{
            this.props.logout();
        }
        this.setState({hasError: false});
    }

    async componentDidCatch(error, info) {
        const currentDate = new Date(Date.now());
        const date = currentDate.getMonth() + "/" +
        currentDate.getDate() + "/" +
        currentDate.getFullYear() + " at " +
        currentDate.getHours() + ":" +
        currentDate.getMinutes() + ":" +
        currentDate.getSeconds();

        const errorWithInfo = {
            message: error.message,
            info,
            time: date
        };
        console.error(errorWithInfo.message + " occured " + errorWithInfo.info.componentStack +  "\nTime is: " + errorWithInfo.time);
        await this.props.catchErrorAsync(errorWithInfo);
    }

    render() {
        const {hasError} = this.state;
        const {classes} = this.props;
        if (hasError || this.props.state.error ) {
            return (
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" component="h3">
                            {this.props.state.error ? this.props.state.error : 'Oooops... Service temporary unavailable. Click "Back" button please!'}
                        </Typography>
                        {!this.props.state.error && <Button onClick={this.handleBackClick}>Back</Button>}
                    </Paper>
                </div>
            );
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any.isRequired,
    catchErrorAsync: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    state:PropTypes.object.isRequired,
    closeService: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
};


export default connect(mapState, mapDispatch)(withStyles(styles)(ErrorBoundary));
