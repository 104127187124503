import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root:{
        color: '#FFF'
    }
});

class AxurisButton extends Component {
    render() {
        return <Button onClick={this.props.onClick} className={classNames(this.props.customClass, this.props.classes.root)} variant="contained" color="primary">{this.props.children}</Button>;
    };
}

AxurisButton.propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object,
    customClass: PropTypes.object,
    onClick: PropTypes.func
};

export default withStyles(styles)(AxurisButton);