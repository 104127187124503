import jwtDecode from "jwt-decode";
import { USER_ROLES } from "../constants";

export class RoleService {
	static _instance;

	constructor() {
		if (!RoleService._instance) {
			RoleService._instance = this;
		}
		this.userData = null;
		this.roles = null;
	}

	static get instance() {
		if (!RoleService._instance) {
			RoleService._instance = new RoleService();
		}
		return RoleService._instance;
	}

	setRolesFromJwt(jwt) {
		if (!jwt) return null;
		const token = jwt.replace("Bearer ", "");
		const decodedJwt = jwtDecode(token);

		this.userData = this.setUserDataFromJwt(decodedJwt);
		this.roles = this.getUserRoles(decodedJwt);
	}

	setUserDataFromJwt(decodedJwt) {
		return {
			parentGroupId: decodedJwt.parent_group_id,
			id: decodedJwt.sub,
			userRole: this.getUserRoles(decodedJwt)
		};
	}

	getUserRoles(decodedJwt) {
		const rolesObject = {};
		decodedJwt.RolesKey.forEach((role) => {
			rolesObject[role.authority.toUpperCase()] = role.authority.toUpperCase();
		});

		return rolesObject;
	}

	isUserAdmin(roles) {
		return this.isUserHasRole(USER_ROLES.ROLE_ADMIN, roles);
	}

	isNormalUser(roles) {
		return this.isUserHasRole(USER_ROLES.ROLE_NORMAL_USER, roles);
	}

	isUser(roles) {
		return this.isUserHasRole(USER_ROLES.ROLE_USER, roles);
	}

	isActivityNumberUser(roles) {
		return this.isUserHasRole(USER_ROLES.ROLE_ACTIVITY_NUMBER_USER, roles);
	}

	isUserHasRole(role, roles) {
		if (roles && typeof role === "string") {
			return roles[role.toUpperCase()] ? true : false;
		}
		if (role && typeof role === "string") {
			return this.roles[role.toUpperCase()] ? true : false;
		}
	}

	getRolesFromString(string) {
		const arrayOfRoles = JSON.parse(string);

		const roles = {};

		arrayOfRoles.forEach((role) => {
			const updatedRole = "ROLE_" + role.label.toUpperCase();

			roles[updatedRole] = updatedRole;
		});

		return roles;
	}

	getCurrentUserData() {
		return this.userData;
	}
}
