export const SERVICES_ENUM = {
	MY_ORG: "myorg",
	MY_PEOPLE: "myPeople",
	MY_PTT: "myPtt",
	MY_CARRIER: "myCarrier",
	MY_PBX: "mypbx",
	MY_TRANSLATIONS: "mytranslations",
	MY_ASSETS_TYPE: "myAssetTypes",
	MY_ASSETS: "myAssets",
	MY_CUSTOMERS: "myCustomers",
	MY_CUSTOMER_OBJECTS: "myCustomerObjects",
	MY_AGENDA: "myAgenda",
	MY_LOCATOR: "myLocator",
	MY_NOTIFICATIONS: "myNotifications",
	MY_PERMISSIONS: "myPermissions",
	MY_REPORTS: "myReports",
	MY_SKILLS: "mySkills",
	MY_SOW: "mySow",
	MY_TICKETS: "myTickets",
	MY_WORKTIME: "myWorktime",
	MY_ALARMS: "myAlarms",
	LWP: "lwp",
	MY_ALARM_TYPES: "myAlarmTypes",
	MY_ALARM_GROUPS: "myAlarmGroups",
};

export const HIDDEN_ITEMS = {
	roleSearcher: false,
	translationButon: true,
	helpButton: true,
	imagePicker: false,
};

export const ITEM_IMG = {
	MY_ORG: "../axstandard/icons/ax-alarm-groups-service.svg",
	MY_PEOPLE: "../axstandard/icons/ax-people-service.svg",
	MY_PTT: "../axstandard/icons/ax-ptt-service.svg",
	MY_CARRIER: "../axstandard/icons/ax-carrier-service.svg",
	MY_PBX: "../axstandard/icons/ax-pbx-service.svg",
	MY_TRANSLATIONS: "../axstandard/icons/ax-translations-service.svg",
	MY_ASSETS_TYPE: "../axstandard/icons/ax-asset-types-service.svg",
	MY_ASSETS: "../axstandard/icons/ax-assets-service.svg",
	MY_CUSTOMERS: null,
	MY_CUSTOMER_OBJECTS: null,
	MY_AGENDA: null,
	MY_LOCATOR: "../axstandard/icons/ax-locator-service.svg",
	MY_NOTIFICATIONS: null,
	MY_PERMISSIONS: "../axstandard/icons/ax-permissions-service.svg",
	MY_REPORTS: null,
	MY_SKILLS: null,
	MY_SOW: null,
	MY_TICKETS: null,
	MY_WORKTIME: "../axstandard/icons/ax-worktime-service.svg",
	MY_ALARMS: "../axstandard/icons/ax-alarms-service.svg",
	LWP: "../axstandard/icons/ax-lwp-service.svg",
	MY_ALARM_TYPES: "../axstandard/icons/ax-alarm-types-service.svg",
	MY_ALARM_GROUPS: "../axstandard/icons/ax-alarm-groups-service.svg",
};

export const ITEM_ICON = {
	MY_ORG: "../axstandard/icons/ax-group-small.svg",
	MY_PEOPLE: "../axstandard/icons/ax-user-small.svg",
	MY_PTT: "../axstandard/icons/ax-ptt-service.svg",
	MY_CARRIER: "../axstandard/icons/ax-carrier-service.svg",
	MY_PBX: "../axstandard/icons/ax-pbx-service.svg",
	MY_TRANSLATIONS: "../axstandard/icons/ax-dictionnary-small.svg",
	MY_ASSETS_TYPE: "../axstandard/icons/ax-assets-small.svg",
	MY_ASSETS: "../axstandard/icons/ax-assets-small.svg",
	MY_CUSTOMERS: null,
	MY_CUSTOMER_OBJECTS: null,
	MY_AGENDA: null,
	MY_LOCATOR: null,
	MY_NOTIFICATIONS: null,
	MY_PERMISSIONS: "../axstandard/icons/ax-permissions-service.svg",
	MY_REPORTS: null,
	MY_SKILLS: null,
	MY_SOW: null,
	MY_TICKETS: null,
	MY_WORKTIME: null,
	MY_ALARMS: "../axstandard/icons/ax-alarm-types-small.svg",
	LWP: "../axstandard/icons/ax-lwp-small.svg",
	MY_ALARM_TYPES: "../axstandard/icons/ax-alarm-types-small.svg",
	MY_ALARM_GROUPS: "../axstandard/icons/ax-alarm-groups-small.svg",
};

export const TIMER_ACTIVITY_STATE = {
	RUNNING: "RUNNING",
	PAUSED: "PAUSED",
	STOPPED: "STOPPED",
};

export const WORK_TIME_VIEW_MODE = {
	CALENDAR: "CALENDAR",
	LIST: "LIST",
	REPORT: "REPORT",
	VACATIONS: "VACATIONS",
};

export const LOGIN_PAGE_LANGUAGES = ["en", "fr", "de", "it", "ua"];

export const SERVICES_FOR_USER = {
	[SERVICES_ENUM.MY_PEOPLE]: SERVICES_ENUM.MY_PEOPLE,
	[SERVICES_ENUM.MY_WORKTIME]: SERVICES_ENUM.MY_WORKTIME,
	[SERVICES_ENUM.MY_ALARMS]: SERVICES_ENUM.MY_ALARMS,
	[SERVICES_ENUM.MY_LOCATOR]: SERVICES_ENUM.MY_LOCATOR,
	[SERVICES_ENUM.MY_PTT]: SERVICES_ENUM.MY_PTT,
	[SERVICES_ENUM.MY_CARRIER]: SERVICES_ENUM.MY_CARRIER,
	[SERVICES_ENUM.MY_PBX]: SERVICES_ENUM.MY_PBX,
};

export const SERVICES_FOR_NORMAL_USER = {
	[SERVICES_ENUM.MY_WORKTIME]: SERVICES_ENUM.MY_WORKTIME,
	[SERVICES_ENUM.MY_PEOPLE]: SERVICES_ENUM.MY_PEOPLE,
	[SERVICES_ENUM.MY_PTT]: SERVICES_ENUM.MY_PTT,
	[SERVICES_ENUM.MY_CARRIER]: SERVICES_ENUM.MY_CARRIER,
	[SERVICES_ENUM.MY_PBX]: SERVICES_ENUM.MY_PBX,
};

export const USER_ROLES = {
	ROLE_USER: "ROLE_USER",
	ROLE_ADMIN: "ROLE_ADMIN",
	ROLE_NORMAL_USER: "ROLE_NORMAL_USER",
	ROLE_ACTIVITY_NUMBER_USER: "ROLE_ACTIVITY_NUMBER_USER",
};
export const OPTIONS_ROLES = {
	USER: "USER",
	ADMIN: "ADMIN",
	NORMAL_USER: "NORMAL_USER",
	ACTIVITY_NUMBER_USER: "ACTIVITY_NUMBER_USER",
};

export const subdomain = window.location.host.split('.')[0];
export const subdomainEnv = window.location.host.split('.')[1];