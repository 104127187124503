import React from 'react';
import ReactDOM from 'react-dom';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TabBar from './TabBar/TabBar';
import styles, {icons} from './styles';
import TooltipButton from '../TooltipButton';
import ExtraCall from "../ExtraCall";

const mapState = state => ({
    state: state.core,
    isBobstSubdomain: state.core.isBobstSubdomain,
});

const mapDispatch = dispatch => ({
    changeMobileMode: isMobileMode => dispatch.core.changeMobileMode(isMobileMode),
    fetchAlarmTypesAsync: () => dispatch.core.fetchAlarmTypesAsync(),
    callTestAlarmAsync: (data) => dispatch.core.callTestAlarmAsync(data),
    switchToService: tabId => dispatch.core.switchToService(tabId),
    closeService: tabId => dispatch.core.closeService(tabId),
    reset: tabId => {
        if (dispatch[tabId] && dispatch[tabId].reset) {
            return dispatch[tabId].reset();
        }
    },
    logout: () => dispatch.core.logout(),
});

class TabContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tabBarShift: 0,
            isFullScreen: false,
        };
    }

    async componentDidMount() {
        await this.props.fetchAlarmTypesAsync();
        this.checkWindowWidth();
        window.addEventListener('resize', this.checkWindowWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowWidth);
    }

    checkWindowWidth = () => {
        const { innerWidth } = window;
        const { changeMobileMode, state } = this.props;
        const isMobileMode = innerWidth <= 800;
        if (state && state.isMobileMode !== isMobileMode) {
            changeMobileMode(isMobileMode);
        };
    };

    calculateOffest(tabBarShift, nbIncrements) {
        if (Math.abs(tabBarShift) >= nbIncrements) {
            return -nbIncrements - 0.2;
        }
        return Math.round(tabBarShift);

    }

    handleSizeChange = (containerWidth, contentWidth) => {
        const nbIncrements = (Math.abs(contentWidth - containerWidth) / 100);
        const { tabBarShift } = this.state;
        const shiftVal = this.calculateOffest(tabBarShift, nbIncrements);

        this.setState({
            tabBarInnerScrollWidth: contentWidth,
            tabBarWidth: containerWidth,
            displayArrows: contentWidth > containerWidth,
            nbIncrements,
            tabBarShift: contentWidth > containerWidth ? shiftVal : 0
        });
    }

    handleArrowClick = (arrow) => {
        const { nbIncrements } = this.state;
        let tabBarShift = arrow === 'left' ? this.state.tabBarShift + 1 : this.state.tabBarShift - 1;
        tabBarShift = this.calculateOffest(tabBarShift, nbIncrements);

        this.setState({
            tabBarShift,
            tabBarShiftDirection: arrow
        });
    }

    handleCloseTab = (event, tabId) => {
        const { tabContents } = this.props;

        for (let i = 0; i < tabContents.length; i++) {
            if (tabContents[i].tabId === tabId) {
                if (tabContents[i].selected) {
                    this.props.switchToService(tabContents[i - 1].tabId);
                }
                tabContents.splice(i, 1);
                break;
            }
        }

        this.props.closeService(tabId);
        this.props.reset(tabId);
        event.stopPropagation();
    }


    fullScreen = () => {
        if(!this.state.isFullScreen) {
            document.documentElement.requestFullscreen();
            this.setState({
                isFullScreen: true,
            });
        } else {
            document.exitFullscreen();
            this.setState({
                isFullScreen: false,
            });
        }
    }

    logout = () => {
        this.props.logout();
    }

    remderLogoutButton = () => {
        const { state, classes } = this.props;
        const el = document.querySelector('#root');
        const button = (
            <TooltipButton
                customClass={classes.logout}
                icon={icons.logout}
                title='Logout'
                onClick={this.logout}
            />
        );
        if (state.isMobileMode) {
            return ReactDOM.createPortal(
                button,
                el,
            );
        }
        return button;
    }

    render() {
        const { classes, size, switchToService, state, alarms, tabContents, callTestAlarmAsync } = this.props;
        const isBobstSubdomain = state && state.isBobstSubdomain;
        const activeContent = tabContents.map((tabContent, index) =>
            (tabContent.selected ? <div className={classes.tabContentWrapper} key={index}>{tabContent.content}</div> : '')
        );
        const extraAlarmTypes = state && state.extraAlarmTypes;

        let parentWidth = size ? size.width : this.state.tabBarWidth;
        parentWidth = parentWidth || 0;

        return (
            <Paper className={classes.tabContainer}>
                <Grid container>
                    <Grid item xs={9}>
                        {!state.isMobileMode && (
                            <TabBar
                                tabContents={tabContents}
                                onCloseTab={this.handleCloseTab}
                                onTabSwitch={switchToService}
                                onSizeChange={(contentWidth) => {this.handleSizeChange(parentWidth, contentWidth);}}
                                displayArrows={this.state.displayArrows}
                                onSize={(containerSize) => this.handleSizeChange(containerSize.width, this.state.tabBarInnerScrollWidth)}
                                onMouseDownOnArrow={this.handleArrowClick}
                                tabBarShift={this.state.tabBarShift}
                                tabBarShiftDirection={this.state.tabBarShiftDirection}
                                tabBarNbScrollIncrements={this.state.nbIncrements}
                                isMobileMode={state.isMobileMode}
                                alarms={alarms}
                            />
                        )}

                    </Grid>
                    <Grid item sx={3}>
                        <div className={classes.sideContent}>
                            <div className={classes.logo} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.tabPane}>
                            {state.isMobileMode && (
                                <TabBar
                                    tabContents={tabContents}
                                    onCloseTab={this.handleCloseTab}
                                    onTabSwitch={switchToService}
                                    onSizeChange={(contentWidth) => {this.handleSizeChange(parentWidth, contentWidth);}}
                                    displayArrows={this.state.displayArrows}
                                    onSize={(containerSize) => this.handleSizeChange(containerSize.width, this.state.tabBarInnerScrollWidth)}
                                    onMouseDownOnArrow={this.handleArrowClick}
                                    tabBarShift={this.state.tabBarShift}
                                    tabBarShiftDirection={this.state.tabBarShiftDirection}
                                    tabBarNbScrollIncrements={this.state.nbIncrements}
                                    isMobileMode={state.isMobileMode}
                                    alarms={alarms}
                                />
                            )}
                            <div className={classes.topShadow} />
                            {this.remderLogoutButton()}
                            {isBobstSubdomain && <ExtraCall
                                items={extraAlarmTypes}
                                callTestAlarmAsync={callTestAlarmAsync}
                                dictionaries={state.dictionaries}
                                currentLanguage={state.currentLanguage}
                            />}
                            {activeContent}
                        </Paper>
                    </Grid>
                </Grid>
                { !state.isMobileMode  && (
                    <TooltipButton
                        customClass={classes.fullScreen}
                        icon={this.state.isFullScreen ? icons.exitFullScreen : icons.fullScreen}
                        title={this.state.isFullScreen ? 'Exit full screen' : 'Full screen'}
                        onClick={this.fullScreen}
                    />
                )}
            </Paper>
        );
    }
}

TabContainer.propTypes = {
    state: PropTypes.object,
    tabContents: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    size: PropTypes.number,
    closeService: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    switchToService: PropTypes.func.isRequired,
    callTestAlarmAsync: PropTypes.func.isRequired,
    fetchAlarmTypesAsync: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    changeMobileMode: PropTypes.func.isRequired,
    alarms: PropTypes.array,
};

export default connect(mapState, mapDispatch)(withStyles(styles)(TabContainer));
