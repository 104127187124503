import btoa from 'btoa';
import SearchResult from './SearchResult';
import communicationAgent from '../CommunicationAgent';

class SearchUtil {

    async searchFor(searchCriteria) {
        let json = {
            searchTerm: searchCriteria.getSearchTerm(),
            itemTypeOrder: searchCriteria.getTypeOrder(),
            itemSubTypeOrder: searchCriteria.getSubTypeOrder(),
            limit: searchCriteria.getLimit() || 1000
        };
        json = JSON.stringify(json);
        const base64JsonParameters = btoa(json);
        let result = [];
        await communicationAgent.get(`/mysearch/api/v1/items/${base64JsonParameters}`)
            .then(res => {
                result = res.data;
            });

        const searchResults = [];
        
        result.forEach(rawResult => {
            if(!rawResult.index){
                return;
            }
            const searchResult = new SearchResult(rawResult.index);
            rawResult.fields && Object.keys(rawResult.fields).forEach(key => {
                searchResult.addField(key, rawResult.fields[key]);
            });

            searchResults.push(searchResult);
        });

        return searchResults;
    }

    async addToSearch(itemId, indexRequest) {
        await communicationAgent.post(`/mysearch/api/v1/items/${itemId}`, indexRequest);
    }

    async updateSearch(itemId, indexRequest) {
        await communicationAgent.put(`/mysearch/api/v1/items/${itemId}`, indexRequest);
    }

    async deleteFromSearch(itemId) {
        await communicationAgent.delete(`/mysearch/api/v1/items/${itemId}`);
    }
}

export default SearchUtil;