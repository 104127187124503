import { SERVICES_ENUM } from '../constants';

export default class TabsIconUtils {
    /**
     * Todo need change object to icon.
     * @param {string} tabId 
     */
    static getTabIconData(tabId) {
        switch(tabId) {
        case SERVICES_ENUM.MY_ORG : {
            return {text: 'Or', color: '#fbc000'};
        }
        case SERVICES_ENUM.MY_PEOPLE : {
            return {text: 'Pe', color: '#3bbee3'};
        }
        case SERVICES_ENUM.MY_TRANSLATIONS : {
            return {text: 'Tr', color: '#ff95f6'};
        }
        case SERVICES_ENUM.MY_ASSETS_TYPE : {
            return {text: 'At', color: '#b27c4f'};
        }
        case SERVICES_ENUM.MY_ASSETS : {
            return {text: 'As', color: '#9f98b6'};
        }
        case SERVICES_ENUM.MY_CUSTOMERS : {
            return {text: 'Cu', color: '#94cb09'};
        }
        case SERVICES_ENUM.MY_CUSTOMER_OBJECTS : {
            return {text: 'Co', color: '#9e97b5'};
        }
        case SERVICES_ENUM.MY_AGENDA : {
            return {text: 'Ag', color: '#b27c4f'};
        }
        case SERVICES_ENUM.MY_LOCATOR : {
            return {text: 'Lo', color: '#61de93'};
        }
        case SERVICES_ENUM.MY_NOTIFICATIONS : {
            return {text: 'No', color: '#9892fc'};
        }
        case SERVICES_ENUM.MY_PERMISSIONS : {
            return {text: 'Pr', color: '#9893fc'};
        }
        case SERVICES_ENUM.MY_REPORTS : {
            return {text: 'Re', color: '#ff88f5'};
        }
        case SERVICES_ENUM.MY_SKILLS : {
            return {text: 'Sk', color: '#be6bcb'};
        }
        case SERVICES_ENUM.MY_SOW : {
            return {text: 'Sw', color: '#fb7831'};
        }
        case SERVICES_ENUM.MY_TICKETS : {
            return {text: 'Ti', color: '#9e96b6'};
        }
        case SERVICES_ENUM.MY_WORKTIME : {
            return {text: 'Wt', color: '#ff8cf5'};
        }
        case SERVICES_ENUM.MY_ALARMS : {
            return {text: 'Al', color: '#E22011'};
        }
        case SERVICES_ENUM.LWP : {
            return {text: 'Lw', color: '#837BFC'};
        }
        case SERVICES_ENUM.MY_ALARM_TYPES : {
            return {text: 'Ay', color: '#fb7831'};
        }
        case SERVICES_ENUM.MY_ALARM_GROUPS : {
            return {text: 'Au', color: '#fbc000'};
        }
        case SERVICES_ENUM.MY_PTT : {
            return {text: 'Pt', color: '#61de93'};
        }
        default : {
            return null;
        }
        }
    };

}