const styles = theme => ({
    root: {
        padding:theme.spacing.unit * 2,
        overflow: 'auto',
    },
    tileCell: {
        marginBottom:theme.spacing.unit * 2,
    },
    titleWrapper: {
        height: '30px',
    },
    servicesListWrapper: {
        width: '100%',
        paddingBottom: '40px',
        boxSizing: 'border-box',
    },
    showAllButtonContainer: {
        textAlign: 'center',
        width: '100%',
    },
    moreButton: {
        color: 'red',
        position: 'relative',
        '&:before': {
            content: '""',
            width: '11px',
            height: '11px',
            borderTop: '2px solid #fff',
            borderLeft: '2px solid #fff',
            position: 'absolute',
            transform: 'rotate(-135deg)',
            top: '12px',
            left: '14px',
            transition: 'transform 300ms',
        },
    },
    showLess: {
        '&:before': {
            transform: 'rotate(45deg)',
            top: '18px',
        }
    },
    servicesBox: {
        transition: 'all 300ms',
        overflow: 'hidden',
    },
    servicesBoxOpen: {
        color: 'blue',
    }
    // showAllButton: {
    //     color: '#046eac',
    //     cursor: 'pointer',
    //     border: 0,
    //     background: 'transparent',
    //     fontSize: '16px',
    //     outline: 'none',
    // },
    
});

export default styles;