import React from 'react';
import sizeMe from 'react-sizeme';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';


function TabBarScrollArea(props) {

    const {classes, tabBarShift, children} = props;

    const inlineStyle = {
        marginLeft:`${tabBarShift * 100  }px`,
    };
    return (
        <div style={inlineStyle} className={classes.tabBarInnerScroll}>
            {children}
        </div>
    );
}

TabBarScrollArea.propTypes = {
    classes: PropTypes.object.isRequired,
    tabBarShift: PropTypes.number.isRequired,
    children: PropTypes.array.isRequired
};

export default withStyles(styles)(sizeMe()(TabBarScrollArea));