import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import styles from './styles';
import model from '../homeModel';
import UserServiceAreaWidget from '../widgets/UserServiceAreaWidget';
import SearchField from '../../search/widgets/SearchWidget';
import TranslationUtil from '../../../utils/TranslationUtil';
import StoreUtils from '../../../utils/StoreUtils';
import HomeSearcher from '../../../utils/search/HomeSearcher';
import { SERVICES_ENUM } from '../../../constants';
import { RoleService } from '../../../utils/RoleService';

const mapState = state => ({
    state: state.home,
    services: state.core.allServices,
    dictionaries: state.core.dictionaries,
    userRoles: state.core.userRoles,
    currentLanguage: state.core.currentLanguage,
    isMobileMode: state.core.isMobileMode,
});

const mapDispatch = dispatch => ({
    updateCoreTranslations: () => dispatch.core.updateCoreTranslations(),
    fetchTemplatesAsync: () => dispatch.core.fetchTemplatesAsync(),
    setupTranslations: (translations) => dispatch.home.setupTranslations(translations),
    fetchTranslationsDataAsync: () => dispatch.core.fetchTranslationsDataAsync(),
    setupCurrentLanguage: (language) => dispatch.core.setupCurrentLanguage(language),
    communicateWithService: ({serviceName, item}) => dispatch.core.communicateWithService({serviceName, item}),
});

class HomeScreen extends React.Component {

    constructor(props) {
        super(props);
        StoreUtils.onAddNewService(model);
    }

    async componentDidMount() {
        await this.props.fetchTemplatesAsync();
        await this.getTranslationData();
        this.setupTranslations();
        this.props.updateCoreTranslations();
    }

    /**
     * Get translation data and language from back-end.
     */
    async getTranslationData() {
        const lang = TranslationUtil.getDefaultLanguage();
        this.props.setupCurrentLanguage(lang);
        await this.props.fetchTranslationsDataAsync();
    }

    /**
     * Setup tranlation for current tab.
     */
    setupTranslations() {
        const { dictionaries, currentLanguage, setupTranslations } = this.props;
        const translations = TranslationUtil.getCurrentTranslations(dictionaries, currentLanguage, 'Home');
        setupTranslations(translations);
    }

    onSuggestionClick = (val) => {
        let serviceName = null;
        if (val.itemType === 'asset') {
            serviceName = SERVICES_ENUM.MY_ASSETS; 
        } else if (val.itemType === 'group') {
            serviceName = SERVICES_ENUM.MY_ORG;
        } else if (val.itemType === 'user') {
            serviceName = SERVICES_ENUM.MY_PEOPLE;
        }

        if (serviceName) {
            const payload = {
                serviceName,
                item: {
                    assetTypeId: val.subTypeId,
                    deviceId: null,
                    id: val.itemId,
                    itemType: val.itemType,
                    itemTypeId: val.itemType,
                    parentGroupId: val.parentGroupId,
                },
            };
            this.props.communicateWithService(payload);
        }
    }

    search = async (searchValue) => {
        const { translations } = this.props.state;
        const suggestions = await HomeSearcher.search(searchValue, translations);
        return suggestions;
    }

    render () {
        const { classes, state, openService, services, isMobileMode } = this.props;
        const translations = state && state.translations;

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>

                    {RoleService.instance.isUserAdmin() && (
                        <>
                            {!isMobileMode && <Grid item xs={2} />}
                            <Grid item xs={8}>
                                <SearchField
                                    searchAsync={this.search}
                                    onSuggestionClick={this.onSuggestionClick}
                                    placeholder={TranslationUtil.getTranslation(translations, "search_placeholder")}
                                />
                            </Grid>
                            {!isMobileMode && <Grid item xs={2} />}
                        </>
                    )}

                    {!isMobileMode && <Grid item xs={false} sm={1} />}
                    <Grid item xs={isMobileMode ? 12 : 10} className={classes.servicesGrid}>
                        <UserServiceAreaWidget
                            openService={openService}
                            classes={classes}
                            services={services}
                            translations={translations}
                        />
                    </Grid>

                    {!isMobileMode && <Grid item xs={false} sm={1} />}
                </Grid>
            </div>
        );

    }

}

HomeScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
    fetchTemplatesAsync: PropTypes.func.isRequired,
    fetchTranslationsDataAsync: PropTypes.func.isRequired,
    setupTranslations: PropTypes.func,
    setupCurrentLanguage: PropTypes.func.isRequired,
    dictionaries: PropTypes.any.isRequired,
    currentLanguage: PropTypes.string,
    updateCoreTranslations: PropTypes.func.isRequired,
    openService: PropTypes.func,
    services: PropTypes.array,
    isMobileMode: PropTypes.bool.isRequired,
    communicateWithService: PropTypes.func.isRequired,
};

// Exporting with CSS in JS and State/Reducers (Rematch/Redux)
export default connect(mapState, mapDispatch)(withStyles(styles)(HomeScreen));