import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';


/**
 * Component that displays a dummy message.
 * Used only in order to demo how to write components.
 */
class Title extends React.Component {

    /**
     * This is where we render the component.
     */
    render () {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="title">{this.props.children}</Typography>
                <Divider />
            </div>
        );
    }

}

Title.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.string.isRequired
};

export default withStyles(styles)(Title);