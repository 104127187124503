function applyPermissionsToReducers(model) {
    
    if (!model || !model.permissions) {
        return;
    }

    const { reducers } = model;
    const { permissions } = model;
    const reducerNames = Object.keys(permissions);

    for (let r = 0; r < reducerNames.length; r++) {

        const reducerName = reducerNames[r];

        const originalReducer = reducers[reducerName];

        if (!originalReducer || originalReducer.isWrapped) {
            continue;
        }

        reducers[reducerName] = function () {

            return originalReducer.apply(this, arguments);
        };
        reducers[reducerName].isWrapped = true;
    }
}

export default class StoreUtils {
    
    static store;

    static setStore(store) {
        this.store = store;
    }

    /**
     * Before applying the model to the store, we wrap all the
     * reducers into a function that checks the associated 
     * permission of the user.
     * @param {any} model of a service as per Rematch lib.
     */
    
    static onAddNewService(newServiceModel) {
        applyPermissionsToReducers(newServiceModel);
        this.store && this.store.model(newServiceModel);
    }
}