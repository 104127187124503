import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import TabBarScrollArea from '../TabBarScrollArea';
import ClosableTab from './ClosableTab/ClosableTab';
import TabsIconUtils from '../../../../../utils/tabsIconUtils';


function renderTabsContent (tabContents, classes, onCloseTab, onTabSwitch, alarms) {

    const tabs = tabContents.map((tabContent, index) => {
        const tabIconData = TabsIconUtils.getTabIconData(tabContent.tabId);
        return (
            <div key={index} className={classes.tabGridItem}>
                <ClosableTab
                    onCloseTab={(event) => onCloseTab(event, tabContent.tabId)}
                    onClick={() => onTabSwitch(tabContent.tabId)}
                    closable={tabContent.closable}
                    tabId={tabContent.tabId}
                    selected={tabContent.selected}
                    icon={tabContent.icon}
                    alarms={alarms}
                >
                    {tabIconData && (
                        <div className={classes.tabIcon} style={{borderColor: tabIconData.color}}>
                            <div className={classes.tabIconText} style={{color: tabIconData.color}}>{tabIconData.text}</div>
                        </div>
                    )}
                    {tabContent.label}
                </ClosableTab>
            </div>
        );
    });

    return tabs;
}

function renderArrows (tabBarShift, tabBarNbScrollIncrements, displayArrows, onMouseDownOnArrow, classes) {
    const leftArrowDisabled = tabBarShift === 0;
    const rightArrowDisabled = tabBarShift <= -tabBarNbScrollIncrements;        

    const leftArrow = displayArrows ?     (
        <IconButton
            disabled={leftArrowDisabled}
            className={classes.arrowLeftButton} 
            onMouseDown={() => onMouseDownOnArrow('left')}
        >
            <ArrowLeftIcon className={classes.arrowLeftIcon} />
        </IconButton>
    ) : '';
    const rightArrow = displayArrows ?    (
        <IconButton
            disabled={rightArrowDisabled}
            className={classes.arrowRightButton}
            onMouseDown={() => onMouseDownOnArrow('right')}
        >
            <ArrowRightIcon className={classes.arrowRightIcon} />
        </IconButton>
    ) : '';

    const arrows = {
        left: leftArrow,
        rigth: rightArrow
    };

    return arrows;
}

function TabBar(props) {

    const {
        tabContents,
        classes, 
        onCloseTab, 
        onTabSwitch, 
        tabBarShift, 
        tabBarNbScrollIncrements, 
        displayArrows, 
        tabBarShiftDirection,
        onSizeChange, 
        onMouseDownOnArrow,
        isMobileMode,
        alarms
    } = props;

    const [ isTabsOpen, changeTabsOpenState ] = useState(false);

    const getActive = () => {
        return tabContents.find((tab) => {
            return tab.selected;
        });
    };

    const handleTabSwitch = (tabId) => {
        if (isMobileMode) {
            changeTabsOpenState(false);
        }
        onTabSwitch(tabId);
    };

    const closeList = () => {
        changeTabsOpenState(false);
    };

    const tabs = renderTabsContent(tabContents, classes, onCloseTab, handleTabSwitch, alarms);
    const arrows = renderArrows(tabBarShift, tabBarNbScrollIncrements, displayArrows, onMouseDownOnArrow, classes);
    const activeTab = getActive();
    const tabIconData = TabsIconUtils.getTabIconData(activeTab.tabId); 
    const iconUrl = 'url(\'/' + activeTab.icon + '\')';   

    return (
        <Paper className={classes.tabBar}>
            {!isMobileMode ? (
                <>
                    {arrows.left}
                    <Grid container className={classes.tabBarInner}>
                        <TabBarScrollArea
                            tabBarShiftDirection={tabBarShiftDirection}
                            tabBarShift={tabBarShift} 
                            onSize={(size) => onSizeChange(size.width)}
                        >
                            {tabs}
                        </TabBarScrollArea>
                        
                        
                    </Grid>
                    {arrows.rigth}
                </>
            ) : (
                <div className={classes.mobileTabBar}>
                    <div
                        className={classes.selectedItem}
                        onClick={() => {
                            changeTabsOpenState(!isTabsOpen);
                        }}
                    >
                        {tabIconData ? (
                            <div className={classes.tabIcon} style={{borderColor: tabIconData.color}}>
                                <div className={classes.tabIconText} style={{color: tabIconData.color}}>{tabIconData.text}</div>
                            </div>
                        ) : (
                            <div className={classes.mobileSummaryIcon} style={{backgroundImage: iconUrl}} />
                        )}
                        {activeTab.label}
                        {isTabsOpen ? 
                            <div className={[classes.mobileTabArrow, classes.opendArrow].join(' ')} /> : 
                            <div className={[classes.mobileTabArrow, classes.closedArrow].join(' ')} />
                        }
                    </div>
                    {isTabsOpen && (
                        <>
                            <div 
                                className={classes.mobileTabListOverlay} 
                                onClick={closeList}
                            />
                            <div className={classes.mobileTabsList}>
                                {tabs}
                            </div>
                        </>
                    )}
                </div>
            )}

            
        </Paper>
    );
}

TabBar.propTypes = {
    tabContents: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onCloseTab: PropTypes.func.isRequired,
    onTabSwitch: PropTypes.func.isRequired,
    tabBarNbScrollIncrements: PropTypes.number,
    tabBarShift: PropTypes.number.isRequired,
    displayArrows: PropTypes.bool,
    tabBarShiftDirection: PropTypes.any,
    onSizeChange: PropTypes.func.isRequired,
    onMouseDownOnArrow: PropTypes.func.isRequired,
    isMobileMode: PropTypes.bool.isRequired,
    alarms: PropTypes.array,
};

export default withStyles(styles)(sizeMe()(TabBar));