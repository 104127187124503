const styles = theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    image: {
        width: '27px',
        height: '27px',
        minWidth: '24px',
        paddingRight: '4px',
    },
    imageWrapper: {
        display: 'flex',
    },
    text: {
        fontSize: '18px',
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        marginRight: '18px',
        borderRadius: '20px',
        padding: '3px 8px',
        transition: '.3s',
        '&:hover': {
            background: '#eaeaea',
        }
    },

});

export default styles;